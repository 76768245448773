/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: retry(fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers, { getState }) => {
      const token = getState()?.auth?.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }), { maxRetries: 0 }),

  tagTypes: ['Role', 'Users', 'CurrentUser', 'Packages', 'Businesses', 'Business', 'Channels', 'SocialMedia', 'Contacts', 'Contact', 'ContactTags', 'ContactTag', 'ErrorCode'],
  endpoints: (builder) => ({}),
});
