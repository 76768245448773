/* eslint-disable no-plusplus */

export async function encryptData(data, key) {
  const encodedData = new TextEncoder().encode(data);
  const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Initialization vector

  const encrypted = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    encodedData,
  );

  return { encrypted, iv };
}

export async function decryptData(encryptedData, iv, key) {
  const decrypted = await window.crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    key,
    encryptedData,
  );

  return new TextDecoder()?.decode(decrypted);
}

export function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

// secret key
export async function deriveAndExportKey() {
  const passphrase = 'myStaticPassphrase'; // Use a secure passphrase
  const salt = 'myStaticSalt'; // This should be a secure random value
  const iterations = 100000; // The higher the better, but also the slower
  const hash = 'SHA-256'; // SHA-256 is a good choice for a hash algorithm
  const length = 256; // Key length, here we're going for AES-256

  const baseKey = await window.crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(passphrase),
    { name: 'PBKDF2' },
    false,
    ['deriveBits', 'deriveKey'],
  );

  const derivedKey = await window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: new TextEncoder().encode(salt),
      iterations,
      hash,
    },
    baseKey,
    { name: 'AES-GCM', length },
    true, // set extractable to true
    ['encrypt', 'decrypt'],
  );

  // const exportedKey = await window.crypto.subtle.exportKey('jwk', derivedKey);
  return derivedKey;
}
