import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useRouteError } from 'react-router-dom';

export default function Page404() {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Box id="error-page">
        <Typography variant="h1" component="h1" fontSize="10rem" fontWeight={700}>404</Typography>
        <Box mb={6}>
          <Typography variant="em" component="em" fontSize="1.5rem" fontWeight={700}>{error.statusText || error.message}</Typography>
        </Box>
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
        >
          Go Back
        </Button>
      </Box>
    </Box>
  );
}
