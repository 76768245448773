import React from 'react';
import { THEMES } from '../utils/constants';

const initialState = {
  theme: THEMES.DARK,
  setTheme: () => {},
};

const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }) {
  const [theme, _setTheme] = React.useState(() => {
    const storedTheme = localStorage.getItem('theme');
    return storedTheme ? JSON.parse(storedTheme) : THEMES.DEFAULT;
  });

  const setTheme = (theme) => {
    localStorage.setItem('theme', JSON.stringify(theme));
    _setTheme(theme);
  };

  const contextValue = React.useMemo(() => ({ theme, setTheme }), [theme, setTheme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
