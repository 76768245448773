import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businessId: '',
  business: '',
  channel: '',
  channelId: '',
  conversationId: '',
  senderEmail: '',
  senderName: '',
  recipientId: '',
};

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setBusinessId: (state, action) => {
      state.businessId = action.payload;
    },
    setBusiness: (state, action) => {
      state.business = action.payload;
    },
    setChannel: (state, action) => {
      state.channel = action.payload;
    },
    setChannelId: (state, action) => {
      state.channelId = action.payload;
    },
    setConversationId: (state, action) => {
      state.conversationId = action.payload;
    },
    setSenderEmail: (state, action) => {
      state.senderEmail = action.payload;
    },
    setSenderName: (state, action) => {
      state.senderName = action.payload;
    },
    setRecipientId: (state, action) => {
      state.recipientId = action.payload;
    },
  },
});

export const {
  setBusinessId, setChannelId, setConversationId, setSenderEmail, setSenderName, setRecipientId, setBusiness, setChannel,
} = conversationSlice.actions;
export default conversationSlice.reducer;
