import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { RemoveRedEyeRounded, VisibilityOffRounded } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import showToast from 'utils/custom-toast';
import { useSignUpMutation } from '../../redux/slices/auth/authApi';

function SignUp() {
  const [error, setError] = useState('');
  const [signUp, { data, error: signUpError, isLoading }] = useSignUpMutation();
  const navigate = useNavigate();

  const [passType, setPassType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');

  // password show icon
  const handleShowPassword = () => {
    if (passType === 'password') {
      setPassType('text');
    } else {
      setPassType('password');
    }
  };

  const handleShowConfirmPassword = () => {
    if (confirmPasswordType === 'password') {
      setConfirmPasswordType('text');
    } else {
      setConfirmPasswordType('password');
    }
  };

  useEffect(() => {
    if (signUpError) {
      if (signUpError.data.internalCode === 'ERROR_USER_ALREADY_EXISTS') {
        showToast({ title: 'Warning', message: 'User already exists. Use another email', type: 'warning' });
      } else if (signUpError?.data) {
        // Handle specific error cases with a user-friendly message
        const { errorMessage } = signUpError.data;
        setError(errorMessage);
        toast.error(errorMessage);
      } else {
        // Handle other cases when an error occurs
        setError('An error occurred during sign-up.');
        toast.error(error);
      }
    } else if (data?.isSuccessful) {
      // Successful sign-up, navigate to the desired page
      navigate('/');
      showToast({ message: 'Please check your email for verification', type: 'success' });
    }
  }, [data, signUpError, navigate, error]);

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required('First name is required'),
        lastName: Yup.string().max(255).required('Last name is required'),
        email: Yup.string()
          .email('Must be input a valid email')
          .max(255)
          .required('Email is required'),
        password: Yup.string()
          .max(255)
          .required('Password is required')
          .test('uppercase', 'Must contain at least one uppercase letter', (value) => /[A-Z]/.test(value))
          .test('lowercase', 'Must contain at least one lowercase letter', (value) => /[a-z]/.test(value))
          .test('number', 'Must contain at least one number', (value) => /\d/.test(value))
          .test('special-character', 'Must contain at least one special character', (value) => /[!@#$%^&*()-+=<>?]/.test(value))
          .min(8, 'Must be at least 8 characters'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password')], 'Both passwords need to be the same')
          .required('Confirm password is required'),
      })}
      onSubmit={async (
        values,
        {
          resetForm,
        },
      ) => {
        const userData = {
          name: `${values.firstName} ${values.lastName}`,
          email: values.email,
          password: values.password,
        };
        signUp(userData);
        resetForm();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Stack spacing={2} mb={2}>
            <TextField
              type="text"
              name="firstName"
              label="First name"
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
              InputLabelProps={{ style: { fontSize: '0.825rem' } }}
              inputProps={{ style: { fontSize: '0.825rem' } }}
            />
            <TextField
              type="text"
              name="lastName"
              label="Last name"
              value={values.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={touched.lastName && errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
              InputLabelProps={{ style: { fontSize: '0.825rem' } }}
              inputProps={{ style: { fontSize: '0.825rem' } }}
            />
            <TextField
              type="email"
              name="email"
              label="Email address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
              InputLabelProps={{ style: { fontSize: '0.825rem' } }}
              inputProps={{ style: { fontSize: '0.825rem' } }}
            />
            <TextField
              type={passType}
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                endAdornment:
                passType === 'password' ? (
                  <RemoveRedEyeRounded
                    style={{ cursor: 'pointer' }}
                    onClick={handleShowPassword}
                  />
                ) : (
                  <VisibilityOffRounded
                    style={{ cursor: 'pointer' }}
                    onClick={handleShowPassword}
                  />
                ),
              }}
              my={2}
            />
            <TextField
              type={confirmPasswordType}
              name="confirmPassword"
              label="Confirm password"
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
              InputLabelProps={{ style: { fontSize: '0.825rem' } }}
              InputProps={{
                endAdornment:
                confirmPasswordType === 'password' ? (
                  <RemoveRedEyeRounded
                    style={{ cursor: 'pointer' }}
                    onClick={handleShowConfirmPassword}
                  />
                ) : (
                  <VisibilityOffRounded
                    style={{ cursor: 'pointer' }}
                    onClick={handleShowConfirmPassword}
                  />
                ),
              }}
            />
          </Stack>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            color="primary"
          >
            {isLoading ? <CircularProgress color="inherit" size={20} /> : 'Sign up'}
          </Button>
          <Box mt={3}>
            <Typography variant="p" component="p" fontSize="0.875rem">
              Already have an account?
              {' '}
              <Link component={NavLink} to="/auth/sign-in">
                Sign In
              </Link>
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default SignUp;
