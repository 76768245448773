import { ReactComponent as LogoutIcon } from 'assets/logout.svg';
import styled from 'styled-components/macro';

import {
  Avatar, Badge, Button, Grid, Stack, Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { selectAuth } from 'redux/slices/auth/authSelector';
import { userSignOut } from 'redux/slices/auth/authSlice';
import { removeConstants } from 'redux/slices/role/roleSlice';
import showToast from 'utils/custom-toast';

const Footer = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)} ${(props) => props.theme.spacing(4)};
  /* border-right: 2px solid #e1e3e6; */
  /* border-top: 2px solid #e1e3e6; */
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const LogoutIconStyled = styled(LogoutIcon)`
  width: auto;
  height: auto;
`;

function SidebarFooter({ ...rest }) {
  const { user } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem('auth');
    Cookies.remove('auth');
    dispatch(userSignOut());
    dispatch(removeConstants());
    navigate('/auth/sign-in', { replace: true });
    showToast({ message: 'Logged out successfully' });
  };

  return (
    <Footer {...rest}>
      <Stack direction="row" alignItems="center">
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot"
            >
              {!!user && (
              <Avatar
                alt={user.name}
                src={user?.avatar}
                component={NavLink}
                to="/user-profile/view"
              />
              )}
              {/* Demo data */}
              {!user && (
                <Avatar
                  alt="Lucy Lavender"
                  src="/static/img/avatars/avatar-1.jpg"
                />
              )}
            </FooterBadge>
          </Grid>
          <Grid item>
            {!!user && <FooterText variant="body2">{user.name}</FooterText>}
            <FooterSubText variant="caption">{user?.email}</FooterSubText>
          </Grid>
        </Grid>
        <Button onClick={handleLogOut}>
          <LogoutIconStyled />
        </Button>
      </Stack>
    </Footer>
  );
}

export default SidebarFooter;
