import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterTitle: '',
  searchTitle: '',
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    filterData: (state, action) => {
      state.filterTitle = action.payload;
    },
    searchData: (state, action) => {
      state.searchTitle = action.payload;
    },
  },
});

export const { filterData, searchData } = filterSlice.actions;
export default filterSlice.reducer;
