/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
import Cookies from 'universal-cookie';

export function setCookie(name, accessToken, days) {
  const expires = new Date();
  expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));

  const cookies = new Cookies();
  cookies.set(name, accessToken, {
    path: '/',
    // httpOnly: true,
    secure: process.env.NODE_ENV === 'production', // Only send the cookie over HTTPS
    sameSite: 'strict', // Strict same-site policy for CSRF protection
    expires, // Set the expiration date
  });
}

export function getCookie(name) {
  const cookies = new Cookies(null, { path: '/' });
  return cookies.get(name);
}
