import { apiSlice } from '../api/apiSlice';

export const channelApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChannels: builder.query({
      query: (id) => ({
        url: `/channel/business/id/${id}`,
        method: 'GET',
      }),
      providesTags: ['Channels'],
    }),
    getChannel: builder.query({
      query: (id) => ({
        url: `/channel/id/${id}`,
        method: 'GET',
      }),
      providesTags: ['Channels'],
    }),
    getChannelPages: builder.query({
      query: (channelId) => ({
        url: `/channel/pages?channelId=${channelId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      extraOptions: { maxRetries: 2 },
    }),
    getChannelSecret: builder.query({
      query: (id) => ({
        url: `/channel/id/${id}/secret`,
        method: 'GET',
      }),
    }),
    createChannel: builder.mutation({
      query: (data) => ({
        url: '/channel',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Channels'],
    }),
    updateChannel: builder.mutation({
      query: (data) => ({
        url: '/channel',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Channels'],
    }),
    addShortToken: builder.mutation({
      query: (data) => ({
        url: '/channel/attach-short-token',
        method: 'PATCH',
        body: data,
      }),
    }),
    addPageIdToChannel: builder.mutation({
      query: (data) => ({
        url: '/channel/attach-page-id',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Channels'],
    }),
    deleteChannel: builder.mutation({
      query: (id) => ({
        url: `/channel/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Channels'],
    }),
  }),
});

export const {
  useGetChannelsQuery,
  useGetChannelQuery,
  useGetChannelPagesQuery,
  useGetChannelSecretQuery,
  useCreateChannelMutation,
  useUpdateChannelMutation,
  useAddShortTokenMutation,
  useAddPageIdToChannelMutation,
  useDeleteChannelMutation,
} = channelApi;
