import { Typography, styled } from '@mui/material';

const T3 = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 700,
  lineHeight: '30px',
  fontStyle: 'normal',
  letterSpacing: '0px',
});

export default T3;
