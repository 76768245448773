import {
  Box, Paper, Stack, Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SignInComponent from '../../components/auth/SignIn';
import logo from '../../vendor/logo.svg';

function SignIn() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box>
        <Paper
          sx={{
            p: 4,
            width: '25rem',
            height: 'auto',
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;',
          }}
          elevation={0}
        >
          <Helmet title="Sign In" />
          <Stack spacing={2} mb={4}>
            {/* logo */}
            <Box display="flex" justifyContent="center">
              <img src={logo} alt="logo" width={175} />
            </Box>
            <Typography
              component="h5"
              variant="h5"
              fontWeight="bold"
              fontSize="1.25rem"
              align="center"
                // gutterBottom
              textTransform="capitalize"
            >
              Welcome back
            </Typography>
            <Typography
              component="p"
              variant="body1"
              align="center"
              fontSize="0.875rem"
            >
              Sign in to your account to continue
            </Typography>
          </Stack>
          <SignInComponent />
        </Paper>
      </Box>
    </Box>
  );
}

export default SignIn;
