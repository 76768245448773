const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  constants: {
    admin: null,
    user: null,
  },
  filterTitle: '',
  searchTitle: '',
};

const RoleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setAdminConstants: (state, action) => {
      state.constants.admin = [...action.payload];
    },
    setUserConstants: (state, action) => {
      state.constants.user = [...action.payload];
    },
    removeConstants: (state) => {
      state.constants = {
        admin: null,
        user: null,
      };
    },
    filterRoles: (state, action) => {
      state.filterTitle = action.payload;
    },
    searchRole: (state, action) => {
      state.searchTitle = action.payload;
    },
  },
});

export const {
  setAdminConstants, setUserConstants, removeConstants, filterRoles, searchRole,
} = RoleSlice.actions;
export default RoleSlice.reducer;
