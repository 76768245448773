import { useLocation, useNavigate } from 'react-router-dom';
import styled, { withTheme } from 'styled-components/macro';

import { Menu as MenuIcon } from '@mui/icons-material';
import WestIcon from '@mui/icons-material/West';
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import GetTitleFromRoute from 'utils/routeTitleMap';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  padding: 0 24px;
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function Navbar({ onDrawerToggle }) {
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <AppBar
      position="sticky"
      elevation={0}
      color="inherit"
      sx={{
        boxShadow: '0px 0px 14px 0px #3540520D',
      }}
    >
      <Toolbar disableGutters>
        <Grid container alignItems="center" gap={4}>
          <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          {/* back button */}
          <IconButton onClick={() => navigate(-1)} sx={{ backgroundColor: '#383643' }}>
            <WestIcon />
          </IconButton>
          {/* page title */}
          <Typography
            sx={{
              fontSize: '1.5rem',
              fontWeight: 700,
              lineHeight: '34px',
              fontStyle: 'normal',
            }}
          >
            {GetTitleFromRoute(location.pathname.toString())}
          </Typography>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default withTheme(Navbar);
