import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedContactIds: [],
  selectedContacts: [],
  isAllSelected: false,
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setSelectedContactIds: (state, action) => {
      state.selectedContactIds = action.payload;
    },
    setIsAllSelected: (state, action) => {
      state.isAllSelected = action.payload;
    },
    setSelectContacts: (state, action) => {
      const indexToFilter = state.selectedContacts.findIndex((contact) => contact.id === action.payload.id);

      if (indexToFilter !== -1) {
        state.selectedContacts.splice(indexToFilter, 1);
      } else {
        state.selectedContacts.push(action.payload);
      }
    },
    removeSelectedEmail: (state, action) => {
      state.selectedContacts = state.selectedContacts.filter((contact) => contact.email !== action.payload.email);
      state.selectedContactIds = state.selectedContactIds.filter((id) => id !== action.payload.id);
    },
    removeAllEmails: (state) => {
      state.selectedContacts = [];
      state.selectedContactIds = [];
    },
  },
});

export const {
  setSelectedContactIds, setIsAllSelected, setSelectContacts, removeSelectedEmail, removeAllEmails,
} = contactSlice.actions;
export default contactSlice.reducer;
