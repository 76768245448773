/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import rtkQueryErrorLogger from './rtk-middleware';
import { apiSlice } from './slices/api/apiSlice';
import authReducer from './slices/auth/authSlice';
import contactReducer from './slices/contact/contactSlice';
import conversationReducer from './slices/conversation/conversationSlice';
import filterReducer from './slices/filter/filterSlice';
import roleReducer from './slices/role/roleSlice';

const combinedReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  role: roleReducer,
  filter: filterReducer,
  contact: contactReducer,
  conversation: conversationReducer,
  // ... more reducers
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/userSignOut') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddlewares) => getDefaultMiddlewares().concat(apiSlice.middleware).concat(rtkQueryErrorLogger),
  // middleware: (getDefaultMiddlewares) => getDefaultMiddlewares().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});
