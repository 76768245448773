import { Formik } from 'formik';
import * as Yup from 'yup';

import { RemoveRedEyeRounded, VisibilityOffRounded } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import ContentLoader from 'components/ui/ContentLoader';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResetForgotPasswordMutation } from 'redux/slices/auth/authApi';
import showToast from 'utils/custom-toast';
import logo from 'vendor/logo.svg';

export default function ResetPasswordEntry() {
  const location = useLocation();
  const [resetForgotPassword, { isLoading }] = useResetForgotPasswordMutation();

  const [passType, setPassType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('New Password is required')
      .min(8, 'New Password must be at least 8 characters')
      .matches(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()-+=<>?]).{8,}$',
        'Password must contain 1 uppercase, 1 lowercase, 1 special character and 1 number',
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const handleSubmit = (values) => {
    const data = {
      passwordResetToken: token,
      newPassword: values.newPassword,
    };

    resetForgotPassword(data).unwrap()
      .then((res) => {
        if (res?.isSuccessful) {
          showToast({ message: 'Password reset successfully', type: 'success' });
          navigate('/auth/sign-in');
        }
      }).catch((err) => {
        console.error(err);
        showToast({ title: 'Password Reset Failed', message: 'Password reset failed, Please try again later', type: 'failure' });
      })
      .finally(() => {
        navigate('/auth/sign-in');
      });
  };

  // password show icon
  const handleShowPassword = () => {
    if (passType === 'password') {
      setPassType('text');
    } else {
      setPassType('password');
    }
  };

  const handleShowConfirmPassword = () => {
    if (confirmPasswordType === 'password') {
      setConfirmPasswordType('text');
    } else {
      setConfirmPasswordType('password');
    }
  };

  if (isLoading) <ContentLoader />;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '25rem',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <Brand /> */}
          <Box mb={2}>
            <img src={logo} alt="logo" width={200} />
          </Box>
        </Box>
        <Paper
          sx={{
            p: 4,
            width: 'auto',
            height: 'auto',
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;',
          }}
        >
          <Helmet title="Reset Password" />
          <Typography
            component="h5"
            variant="h5"
            fontWeight="bold"
            fontSize="1.25rem"
            align="center"
            gutterBottom
            textTransform="capitalize"
          >
            Reset Password
          </Typography>
          <Typography
            component="p"
            variant="body1"
            align="center"
            fontSize="0.875rem"
            mb={1}
          >
            Enter New Password
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
                )}
                <Box>
                  {/* new password */}
                  <TextField
                    type={passType}
                    name="newPassword"
                    label="New Password"
                    value={values.newPassword}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    fullWidth
                    helperText={touched.newPassword && errors.newPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputLabelProps={{ style: { fontSize: '0.825rem' } }}
                    InputProps={{
                      endAdornment:
                      passType === 'password' ? (
                        <RemoveRedEyeRounded
                          style={{ cursor: 'pointer' }}
                          onClick={handleShowPassword}
                        />
                      ) : (
                        <VisibilityOffRounded
                          style={{ cursor: 'pointer' }}
                          onClick={handleShowPassword}
                        />
                      ),
                    }}
                    sx={{
                      mb: 2,
                    }}
                  />
                  {/* confirm password */}
                  <TextField
                    type={confirmPasswordType}
                    name="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                    InputLabelProps={{ style: { fontSize: '0.825rem' } }}
                    InputProps={{
                      endAdornment:
                      confirmPasswordType === 'password' ? (
                        <RemoveRedEyeRounded
                          style={{ cursor: 'pointer' }}
                          onClick={handleShowConfirmPassword}
                        />
                      ) : (
                        <VisibilityOffRounded
                          style={{ cursor: 'pointer' }}
                          onClick={handleShowConfirmPassword}
                        />
                      ),
                    }}
                  />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                  }}
                >
                  Reset forgot password
                </Button>
              </form>
            )}
          </Formik>
        </Paper>
      </Box>
    </Box>
  );
}
