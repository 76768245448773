import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetCurrentUserQuery } from 'redux/slices/auth/authApi';
import { selectAuth } from 'redux/slices/auth/authSelector';
import { useGetBusinessesQuery } from 'redux/slices/business/businessApi';
import { useGetChannelsQuery } from 'redux/slices/channel/channelApi';
import {
  BUSINESS_VIEW,
  CONTACT_VIEW, MESSAGE_VIEW, PAYMENT_UPDATE, ROLE_VIEW, SOCIAL_MEDIA_VIEW, USER_VIEW,
} from 'utils/constants';
import SidebarNavList from './SidebarNavList'; // Replace with your actual import path

function SidebarNavSection({ pages, component: Component = 'nav', ...rest }) {
  const { user } = useSelector(selectAuth);
  const {
    data: currentUser,
    isLoading: isUserLoading, isError: isUserError,
  } = useGetCurrentUserQuery();
  const { data: businessData, isLoading: isBusinessLoading } = useGetBusinessesQuery(undefined, { skip: user?.role?.roleType === 'ADMIN' });
  const [businessId, setBusinessId] = useState(null);
  const {
    data: channelData,
    isLoading: isChannelLoading,
  } = useGetChannelsQuery(businessId, { skip: !businessId });

  const isLoading = isUserLoading || isBusinessLoading || isChannelLoading;
  const isError = isUserError; // Extend this as needed for other errors
  const CAN_VIEW_CONVERSATION = businessId && channelData?.length > 0;
  // permissions
  const CAN_MESSAGE_VIEW = currentUser?.role?.privileges
    ?.some((privilege) => privilege?.descName === MESSAGE_VIEW);

  const CAN_UPDATE_PAYMENT = currentUser?.role?.privileges
    ?.some((privilege) => privilege?.descName === PAYMENT_UPDATE);

  // const CAN_VIEW_CHANNEL = currentUser?.role?.privileges
  //   ?.some((privilege) => privilege?.descName === CHANNEL_VIEW);

  const CAN_VIEW_CREATED_USERS = currentUser?.role?.privileges
    ?.some((privilege) => privilege?.descName === USER_VIEW);

  const CAN_VIEW_CONTACTS = currentUser?.role?.privileges
    ?.some((privilege) => privilege?.descName === CONTACT_VIEW);

  const CAN_VIEW_BUSINESS = currentUser?.role?.privileges
    ?.some((privilege) => privilege?.descName === BUSINESS_VIEW);

  const CAN_VIEW_SOCIAL_MEDIA = currentUser?.role?.privileges
    ?.some((privilege) => privilege?.descName === SOCIAL_MEDIA_VIEW);

  const CAN_VIEW_ROLES = currentUser?.role?.privileges
    ?.some((privilege) => privilege?.descName === ROLE_VIEW);

  useEffect(() => {
    if (businessData && businessData.length > 0) {
      // Set the business ID when business data is available
      setBusinessId(businessData[0]?.id);
    }
  }, [businessData]);

  const getFilteredPages = () => {
    if (!currentUser || isLoading) {
      return []; // Return empty or a default set of pages when loading or no user data
    }

    let filteredPages = pages.filter((page) => !['Local SEO', 'Reputation Management', 'Business Management', 'Contact Management'].includes(page.title));

    if (currentUser.company && currentUser.company.pack) {
      const { pack } = currentUser.company;

      if (pack.reputationManagementAllowed && businessData?.length > 0 && channelData?.length > 0) {
        filteredPages = filteredPages.concat(pages.filter((page) => page.title === 'Reputation Management'));
      }

      if (pack.seoAllowed && businessData
        && channelData && businessData?.length > 0 && channelData?.length > 0) {
        filteredPages = filteredPages.concat(pages.filter((page) => page.title === 'Local SEO'));
      }

      if (pack.maxNumberOfBusiness > 0 || pack.isAdditionalBusinessAllowed) {
        filteredPages = filteredPages.concat(pages.filter((page) => page.title === 'Business Management'));
      }

      if (pack.numberOfContactsInContactBook > 0) {
        filteredPages = filteredPages.concat(pages.filter((page) => page.title === 'Contact Management'));
      }
    }

    if (currentUser.role && currentUser.role.roleType === 'ADMIN') {
      filteredPages = filteredPages.filter((page) => page.title !== 'My Subscriptions' && page.title !== 'Payment Methods' && page.title !== 'Business Management' && page.title !== 'Local SEO' && page.title !== 'Reputation Management' && page.title !== 'Business Management' && page.title !== 'Contact Management'); // Example condition for admins
    }

    if (currentUser.role && currentUser.role.roleType === 'USER') {
      filteredPages = filteredPages.filter((page) => page.title !== 'User Management' && page.title !== 'Package Management' && page.title !== 'Error Code'); // Example condition for users
    }

    // sidebar menus rendering based on permissions

    if (!CAN_MESSAGE_VIEW) {
      filteredPages = filteredPages.filter((page) => page.title !== 'Conversations');
    }

    // if no business id and channel id not found
    if (!CAN_VIEW_CONVERSATION) {
      filteredPages = filteredPages.filter((page) => page.title !== 'Conversations');
    }

    if (!CAN_UPDATE_PAYMENT) {
      filteredPages = filteredPages.filter((page) => page.title !== 'My Subscriptions' && page.title !== 'Payment Methods');
    }

    if (!CAN_VIEW_CONTACTS) {
      filteredPages = filteredPages.filter((page) => page.title !== 'Contact Management');
    }

    if (!CAN_VIEW_BUSINESS) {
      filteredPages = filteredPages.filter((page) => page.title !== 'Business Management');
    }

    if (!CAN_VIEW_SOCIAL_MEDIA) {
      filteredPages = filteredPages.filter((page) => page.title !== 'Local SEO' && page.title !== 'Reputation Management' && page.title !== 'Conversations');
    }

    if (!CAN_VIEW_ROLES) {
      filteredPages = filteredPages.filter((page) => page.title !== 'Role Management');
    }

    if (!CAN_VIEW_CREATED_USERS) {
      filteredPages = filteredPages.reduce((acc, page) => {
        if (page.children) {
          const filteredChildren = page.children.filter((child) => child.title !== 'Manage Team');
          // Add the page with filtered children to the accumulator
          acc.push({ ...page, children: filteredChildren });
        } else {
          // If the page has no children, just add it to the accumulator
          acc.push(page);
        }
        return acc;
      }, []);
    }

    return filteredPages;
  };

  const filteredPages = useMemo(
    getFilteredPages,
    [currentUser, isLoading, pages, CAN_MESSAGE_VIEW, businessData, channelData],
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" height={40} sx={{ my: 3, mx: 1 }} />; // Or some loading indicator
  }

  if (isError) {
    return <Alert severity="error">Error fetching user data</Alert>;
  }

  return (
    <Component {...rest}>
      <SidebarNavList pages={filteredPages} depth={0} />
    </Component>
  );
}

export default SidebarNavSection;
