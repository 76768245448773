import { Box, Paper, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import logo from 'vendor/logo.svg';
import ResetPasswordComponent from '../../components/auth/ResetPassword';

function ResetPassword() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 4,
          }}
        >
          {/* <Brand /> */}
          <img src={logo} alt="logo" width={200} />
        </Box>
        <Paper
          sx={{
            p: 4,
            width: 'auto',
            height: 'auto',
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;',
          }}
        >
          <Helmet title="Reset Password" />
          <Box mb={4}>
            <Typography
              component="h5"
              variant="h5"
              fontWeight="bold"
              fontSize="1.25rem"
              align="center"
              gutterBottom
              textTransform="capitalize"
            >
              Reset Password
            </Typography>
            <Typography
              component="p"
              variant="body1"
              align="center"
              fontSize="0.875rem"
              mb={1}
            >
              Enter your email to reset your password
            </Typography>
          </Box>

          <ResetPasswordComponent />
        </Paper>
      </Box>
    </Box>
  );
}

export default ResetPassword;
