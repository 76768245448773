import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectAuth } from 'redux/slices/auth/authSelector';

export default function AdminRoute({ children }) {
  const { user } = useSelector(selectAuth);
  const location = useLocation();

  if (user?.role?.roleType === 'ADMIN') {
    return children;
  }
  return <Navigate to="/" state={{ from: location }} replace />;
}
