import {
  Alert, Box, Button, LinearProgress, Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAddCardMutation, useLazyGetCardQuery } from 'redux/slices/company/companyApi';
import showToast from 'utils/custom-toast';

export default function AddCard() {
  const location = useLocation();
  const [addCard, { data, error }] = useAddCardMutation();
  const [getCard] = useLazyGetCardQuery();

  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionId) {
      const data = {
        sessionId,
      };
      addCard(data);
    }
  }, [addCard, sessionId]);

  useEffect(() => {
    if (data) {
      showToast({ message: 'Card added successfully' });
      getCard().refetch();
      navigate('/payment/all');
    } else if (error) {
      showToast({ title: 'Add Card Failed', message: error?.data?.errorMessage || 'Card add failed. Please try again later', type: 'failure' });
    }
  }, [data, error, navigate]);

  return (
    <>
      <Helmet title="Adding Card" />
      <Box sx={{ width: '100%' }}>
        {!error?.data ? (
          <>
            <Typography variant="h1" component="h1" mb={2}>Redirecting</Typography>
            <LinearProgress />
          </>
        ) : null}
        {error?.data ? (
          <Box textAlign="center">
            <Alert severity="error">Card add failed, Please try again later</Alert>
            <Button onClick={() => navigate('/auth/sign-in')} variant="contained" sx={{ mt: 2 }}>Return Sign In</Button>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
