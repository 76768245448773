import { SentimentVeryDissatisfied as SadIcon } from '@mui/icons-material';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import {
  Box, Card, CardContent, Collapse, Fade, FormControl, Grid, Grow, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Stack, TextField, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Skeleton from '@mui/material/Skeleton';
import ErrorAlert from 'components/ui/ErrorAlert';
import T3 from 'components/ui/T3';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuth } from 'redux/slices/auth/authSelector';
import { useGetBusinessesQuery } from 'redux/slices/business/businessApi';
import { useGetChannelsQuery } from 'redux/slices/channel/channelApi';
import { setBusiness, setChannel, setChannelId } from 'redux/slices/conversation/conversationSlice';
import { sxCustomSpacing } from '../../../theme/globalStyles';

export default function FacebookConversationSelect() {
  const { user } = useSelector(selectAuth);
  const [businessId, setBusinessId] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const { data: businesses } = useGetBusinessesQuery(undefined, { skip: user?.role?.roleType === 'ADMIN' });
  const {
    data: channels, isLoading, isError, error,
  } = useGetChannelsQuery(businessId, { skip: !businessId });
  const [newBusiness, setNewBusiness] = useState(null);
  const [channelSelectVisible, setChannelSelectVisible] = useState(false);
  const { channelId } = useSelector((state) => state.conversation);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const parsedBusiness = JSON.parse(e.target.value);
    setNewBusiness(parsedBusiness);
    setBusinessId(parsedBusiness?.id);
    dispatch(setBusiness(parsedBusiness));
    setChannelSelectVisible(true);

    // Reset channel data and hide channel select when a new business is selected
    setChannelData(null);
    setChannelSelectVisible(false);
  };

  const typographyStyles = {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '26px',
    fontStyle: 'normal',
    letterSpacing: '0px',
  };

  const renderConnectStatus = () => {
    const hasFacebookConnected = channels?.some((channel) => channel?.channelType === 'FACEBOOK');
    const hasGoogleConnected = channels?.some((channel) => channel?.channelType === 'GOOGLE');

    if (!hasFacebookConnected && !hasGoogleConnected) return null;

    const connectionStatus = hasFacebookConnected && hasGoogleConnected
      ? 'Google & Facebook are connected.'
      : hasFacebookConnected
        ? 'Facebook is connected.'
        : 'Google is connected.';

    return (
      <Grid item container direction="column" rowGap={1}>
        <T3>{connectionStatus}</T3>
        <Typography sx={typographyStyles}>
          Change here, change everywhere
        </Typography>
      </Grid>
    );
  };

  const renderChannelSelect = () => {
    const showSkeleton = businessId && isLoading;

    if (showSkeleton) {
      // Show skeleton loader while loading
      return (
        <Fade in={showSkeleton} timeout={500}>
          <Skeleton variant="rectangular" width={210} height={56} />
        </Fade>
      );
    }

    if (!channelSelectVisible) {
      return null;
    }

    if (isError) {
      return <ErrorAlert title={error?.data?.error || 'Something went wrong, Internal server error'} />;
    }

    if (channels?.length === 0) {
      return null;
    }
    return (
      <Collapse in={!showSkeleton} orientation="horizontal">
        {/* <FormControl sx={{ width: '12rem' }} disabled={!businessId}>
          <InputLabel id="select-active">Select Channel</InputLabel>
          <Select
            id="channel"
            name="channel"
            labelId="channel"
            label="Select Channel"
            placeholder="Channels"
            value={JSON.stringify(channelData)}
            onChange={(e) => {
              setChannelData(JSON.parse(e.target.value));
              dispatch(setChannelId(JSON.parse(e.target.value).id));
              dispatch(setChannel(JSON.parse(e.target.value)));
            }}
          >
            {channels?.map((channel) => (
              <MenuItem key={channel.id} value={JSON.stringify(channel)}>
                {channel.pageName || channel.channelType}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <Autocomplete
          id="channel"
          sx={{ width: 200 }}
          options={channels}
          autoHighlight
          getOptionLabel={(option) => option?.pageName || option?.channelType}
          value={channelData}
          onChange={(event, newValue) => {
            setChannelData(newValue);
            if (newValue) { // Check if newValue is not null
              dispatch(setChannelId(newValue.id));
              dispatch(setChannel(newValue));
            } else {
              // Handle the case where the Autocomplete is cleared
              dispatch(setChannelId(null));
              dispatch(setChannel(null));
            }
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <ListItemIcon>
                {option.channelType === 'FACEBOOK' ? <FacebookIcon fontSize="small" /> : <GoogleIcon fontSize="small" />}
              </ListItemIcon>
              <ListItemText primary={option.pageName || option.channelType} />
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Channel"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Collapse>
    );
  };

  const renderNoChannelsMessage = () => {
    if (businessId && !isLoading && !isError && (!channels || channels.length === 0)) {
      return (
        <Grow in={channels.length === 0}>
          <Card>
            <CardContent sx={{
              width: '100%',
            }}
            >
              <Stack justifyContent="center" alignItems="center" spacing={5}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <SadIcon color="primary" />
                  <Typography
                    variant="h6"
                    color="primary"
                  >
                    We feel bad same as you
                  </Typography>

                </Stack>
                <Typography variant="h4" color="textSecondary" fontWeight="medium">
                  No channels are currently available for this business. Please add a channel to proceed
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grow>
      );
    }
    return null;
  };

  useEffect(() => {
    // Auto-show channel select if channels are available for the selected business
    if (businessId && channels?.length > 0) {
      setChannelSelectVisible(true);
    }
  }, [businessId, channels]);

  useEffect(() => {
    if (channelId) {
      navigate('/conversations/facebook', { state: { channelId, inboxType: 'FACEBOOK' } });
    }
  }, [channelId, navigate]);

  return (
    <>
      <Helmet title="Select Inbox" />
      <Grid container direction="column" rowGap={8} sx={{ ...sxCustomSpacing }}>
        {renderConnectStatus() === null ? (
          <Grid item container direction="column" rowGap={1}>
            <T3>No channel is connected</T3>
            <Typography sx={typographyStyles}>
              Change here, change everywhere
            </Typography>
          </Grid>
        ) : renderConnectStatus()}
        <Grid item>
          <Box textAlign="center">
            <T3 sx={{ bgcolor: 'transparent' }}>Kindly choose a business & channel to proceed</T3>
            <Stack mt={4} direction="row" gap={3} justifyContent="center">
              <FormControl sx={{ width: '12rem' }}>
                <InputLabel id="select-active">Select Business</InputLabel>
                <Select
                  id="business"
                  name="business"
                  labelId="business"
                  label="Select Business"
                  placeholder="business"
                  value={newBusiness ? JSON.stringify(newBusiness) : ''}
                  onChange={handleChange}
                >
                  {businesses?.map((business) => (
                    <MenuItem key={business.id} value={JSON.stringify(business)}>
                      {business.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {renderChannelSelect()}
            </Stack>
            <Box mt={4}>
              {renderNoChannelsMessage()}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
