import { Box, Drawer as MuiDrawer, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import logo from 'vendor/rebranding/Orbit_Ginie_Logo_Reversed.svg';
import { imageDefaultStyleContain } from '../../theme/globalStyles';
import Footer from './SidebarFooter';
import SidebarNav from './SidebarNav';

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  color: aliceblue;
  > div {
    border-right: 0;
  }
`;

function Sidebar({ items, showFooter = true, ...rest }) {
  const { palette } = useTheme();
  const backgroundColor = palette.background.default;

  return (
    <Drawer variant="permanent" {...rest}>
      <Box
        component={NavLink}
        to="/"
        sx={{
          background: backgroundColor,
          // borderRight: '2px solid #E1E3E6',
        }}
      >
        <Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '100%', height: '4.0530rem',
            }}
          >
            <img
              src={logo}
              alt="orbitgenie-logo"
              style={{ ...imageDefaultStyleContain, width: '10rem' }}
            />
          </Box>
        </Box>
      </Box>
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
}

export default Sidebar;
