import { rgba } from 'polished';
import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Chip, Collapse, ListItemButton, ListItemText,
} from '@mui/material';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

const Item = styled(ListItemButton)`
  padding-top: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) => props.theme.palette.primary.main};
    span, svg {
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
    &:hover {
      background-color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => rgba(
    props.theme.sidebar.color,
    props.depth && props.depth > 0 ? 0.7 : 1,
  )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
    font-weight: 500;
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

function SidebarNavListItem(props) {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
  } = props;

  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <>
        <Item depth={depth} onClick={handleToggle}>
          {Icon && <Icon />}
          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </>
    );
  }

  return (
    <Item
      depth={depth}
      component={CustomRouterLink}
      to={href}
      activeclassname="active"
    >
      {Icon && <Icon />}
      <Title depth={depth}>
        {title}
        {badge && <Badge label={badge} />}
      </Title>
    </Item>
  );
}

export default SidebarNavListItem;
