import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { RemoveRedEyeRounded, VisibilityOffRounded } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectAuth } from 'redux/slices/auth/authSelector';
import showToast from 'utils/custom-toast';
import { setCookie } from 'utils/secure-cookie';
import {
  arrayBufferToBase64,
  deriveAndExportKey,
  encryptData,
} from 'utils/secure-token';
import { useSendVerificationEmailMutation, useSignInMutation } from '../../redux/slices/auth/authApi';

function SignIn() {
  const [signIn, { data, error: signInError, isLoading }] = useSignInMutation();
  const [sendVerificationEmail, {
    data: sendVerificationEmailData,
    error: sendVerificationEmailError,
  }] = useSendVerificationEmailMutation();
  const navigate = useNavigate();
  const { user } = useSelector(selectAuth);
  const location = useLocation();
  const from = location?.state?.from?.pathname || '/';
  const [userEmail, setUserEmail] = useState('');
  const [errorMessageObj, setErrorMessageObj] = useState({
    errorCode: '',
    errorMessage: '',
  });
  const [key, setKey] = useState(null);

  const [passType, setPassType] = useState('password');

  // password show icon
  const handleShowPassword = () => {
    if (passType === 'password') {
      setPassType('text');
    } else {
      setPassType('password');
    }
  };

  const handleSendVerificationEmail = () => {
    const userData = {
      email: userEmail,
    };
    sendVerificationEmail(userData);
    setUserEmail('');
    setErrorMessageObj({ errorCode: '', errorMessage: '' });
  };

  const handleEncryptAndDecrypt = async (token) => {
    if (key) {
      const { encrypted, iv } = await encryptData(token, key);
      const encryptedBase64 = arrayBufferToBase64(encrypted);
      setCookie('access_token', { encrypted: encryptedBase64, iv: arrayBufferToBase64(iv) }, 1);
    }
  };

  useEffect(() => {
    if (data?.accessToken && data?.refreshToken) {
      setErrorMessageObj({ errorCode: '', errorMessage: '' });
      try {
        const auth = {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          user: data.user,
        };
        handleEncryptAndDecrypt(JSON.stringify(auth));
        setCookie('access_token', auth.accessToken, 1);
        // Navigate to the desired page
        if (user?.role?.roleType === 'ADMIN') {
          navigate(from, { replace: true });
        } else if (!data?.user?.company) {
          navigate('/create-company', { replace: true });
        } else {
          navigate(from, { replace: true });
          showToast({ message: 'Welcome to Orbit Genie' });
        }
      } catch (error) {
        console.error('Error storing token:', error);
        // Handle the error gracefully, e.g., display an error message to the user
      }
    } else if (signInError) {
      setErrorMessageObj({
        errorCode: signInError.data.internalCode,
        errorMessage: signInError.data?.errorMessage,
      });
    }
  }, [data, from, navigate, signInError, user?.role?.roleType]);

  useEffect(() => {
    if (sendVerificationEmailData) {
      toast.success('Verification email has sent, Please check your email');
    } else if (sendVerificationEmailError) {
      toast.error(sendVerificationEmailError.data?.errorMessage);
    }
  }, [sendVerificationEmailData, sendVerificationEmailError]);

  useEffect(() => {
    deriveAndExportKey().then((exportedKey) => {
      // You can now use this exported key (which is a plain object) elsewhere in your application
      setKey(exportedKey);
    });
  }, []);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be input a valid email')
          .max(255)
          .required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (
        values,
      ) => {
        const userVerificationData = { email: values.email, password: values.password };
        setUserEmail(userVerificationData.email);
        await signIn(userVerificationData);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Stack spacing={2}>
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
              InputLabelProps={{ style: { fontSize: '0.825rem' } }}
              inputProps={{ style: { fontSize: '0.825rem' } }}
            />
            <TextField
              type={passType}
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                endAdornment:
                passType === 'password' ? (
                  <RemoveRedEyeRounded
                    style={{ cursor: 'pointer' }}
                    onClick={handleShowPassword}
                  />
                ) : (
                  <VisibilityOffRounded
                    style={{ cursor: 'pointer' }}
                    onClick={handleShowPassword}
                  />
                ),
              }}
              my={2}
            />
          </Stack>
          {
            errorMessageObj.errorCode && errorMessageObj.errorMessage && (
              errorMessageObj.errorCode === 'ERROR_USER_NOT_VERIFIED' ? (
                userEmail && (
                  <Alert mt={2} mb={3} severity="warning" sx={{ textAlign: 'center' }}>
                    Please verify your email or
                    {' '}
                    <br />
                    {' '}
                    Request a new verification email.
                    <Button onClick={handleSendVerificationEmail}>Resend</Button>
                  </Alert>
                )
              ) : (
                <Alert mt={2} mb={3} severity="warning">
                  {errorMessageObj?.errorMessage || 'Something went wrong, Internal Server Error'}
                </Alert>
              )
            )
          }
          <FormControlLabel
            control={(
              <Checkbox
                value="remember"
                color="primary"
                size="small"
                sx={{
                  color: 'primary.main',
                  '&.Mui-checked': { color: 'primary.main' },
                }}
              />
            )}
            label="Remember me"
            sx={{ my: 1 }}
          />
          <Stack spacing={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              color="primary"
            >
              {isLoading ? <CircularProgress color="inherit" size={20} /> : 'Sign in'}
            </Button>
            <Button
              component={NavLink}
              to="/auth/reset-password"
              fullWidth
              color="primary"
            >
              Forgot password
            </Button>
          </Stack>
          <Box mt={1}>
            <Typography variant="p" component="p" fontSize="0.875rem">
              Don&apos;t have an account?
              {' '}
              <Link component={NavLink} to="/auth/sign-up">
                Sign Up
              </Link>
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
