import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useTheme from 'hooks/useTheme';
import { create } from 'jss';
import { RouterProvider } from 'react-router-dom';
import { Toaster } from 'sonner';
import { ThemeProvider } from 'styled-components/macro';
import createTheme from 'theme';
import { router } from './routes/routes/router';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

function App() {
  const { theme } = useTheme();
  return (
    <StylesProvider jss={jss}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={createTheme(theme)}>
          <ThemeProvider theme={createTheme(theme)}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <RouterProvider router={router} />
              <Toaster position="bottom-right" />
            </LocalizationProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
}

export default App;
