/* eslint-disable linebreak-style */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCnnWRASaOSFNp9bW2EPQrZ8pUcYVPEcPY',
  authDomain: 'orbitgenie-fd201.firebaseapp.com',
  projectId: 'orbitgenie-fd201',
  storageBucket: 'orbitgenie-fd201.appspot.com',
  messagingSenderId: '809959419961',
  appId: '1:809959419961:web:a1c71e3f24b4dfb51adde8',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
