/* eslint-disable linebreak-style */
import {
  FacebookAuthProvider, getAuth, onAuthStateChanged, signInWithPopup,
} from 'firebase/auth';
import {
  createContext, useEffect, useMemo, useState,
} from 'react';
import app from '../firebase/firebase.config';

export const AuthContext = createContext();

// firebase auth
const auth = getAuth(app);
const facebookProvider = new FacebookAuthProvider();
facebookProvider.addScope(
  `pages_read_user_content,
public_profile,
pages_manage_posts,
pages_read_engagement,
public_profile,
email,
pages_show_list,
pages_manage_metadata,
pages_messaging,
pages_manage_engagement`,
);

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const facebookLogin = () => {
    setLoading(true);
    return signInWithPopup(auth, facebookProvider);
  };

  // continue checking if a user logged in or not
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const authInfo = useMemo(() => ({
    user,
    loading,
    setLoading,
    facebookLogin,
  }), [user, loading]);

  return (
    <AuthContext.Provider value={authInfo}>
      {children}
    </AuthContext.Provider>
  );
}
