/* eslint-disable import/no-extraneous-dependencies */
// import DisableDevtool from 'disable-devtool';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { isExpired } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import showToast from 'utils/custom-toast';
import { userSignIn, userSignOut } from '../redux/slices/auth/authSlice';

export default function useAuthCheck() {
  const [authChecked, setAuthChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Call DisableDevtool() only if NODE_ENV is production
  // if (process.env.NODE_ENV === 'production') {
  //   DisableDevtool();
  // }

  // const checkUserMatchApi = async (authToken) => {
  //   const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/get-current-user`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       authorization: `Bearer ${authToken}`,
  //     },
  //   });
  //   const data = await response.json();
  //   return data;
  // };

  // const checkUserMatchApi = async (authToken) => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/get-current-user`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error fetching user data:', error);
  //     throw error; // or handle error as needed
  //   }
  // };

  // const checkResponseStatus = async (authToken) => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/get-current-user`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });
  //     return response.status;
  //   } catch (error) {
  //     console.error('Error fetching response status:', error);
  //     // If Axios caught the error, it means the request was made and the server responded with a status code
  //     // that falls out of the range of 2xx, so you can access the status from the error object directly.
  //     return error.response ? error.response.status : 500; // Return 500 as a default or handle as needed
  //   }
  // };

  const checkUserMatchApi = async (authToken) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/get-current-user`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Heuristic to check for a CORS error
      if (!error.response && error.message.includes('Network Error')) {
        return 'CORS_ERROR';
      }
      throw error; // or handle error as needed
    }
  };

  const checkResponseStatus = async (authToken) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/get-current-user`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response.status;
    } catch (error) {
      console.error('Error fetching response status:', error);
      // Heuristic to check for a CORS error
      if (!error.response && error.message.includes('Network Error')) {
        return 'CORS_ERROR';
      }
      // If Axios caught the error, it means the request was made and the server responded with a status code
      // that falls out of the range of 2xx, so you can access the status from the error object directly.
      return error.response ? error.response.status : 500; // Return 500 as a default or handle as needed
    }
  };

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const localAuth = localStorage.getItem('auth');
        const authToken = JSON.parse(localAuth);
        const accessTokenString = authToken?.accessToken;
        const isTokenExpired = isExpired(accessTokenString);

        if (localAuth) {
          const auth = JSON.parse(localAuth);
          if (auth?.accessToken && auth?.user) {
            dispatch(
              userSignIn({
                accessToken: auth.accessToken,
                refreshToken: auth.refreshToken,
                user: auth.user,
              }),
            );

            // Call your API to check if the stored user matches the current user
            const isUserMatch = await checkUserMatchApi(accessTokenString); // Pass the user ID to your API function
            const resStatus = await checkResponseStatus(accessTokenString);

            if (isUserMatch === 'CORS_ERROR' || resStatus === 'CORS_ERROR') {
              dispatch(userSignOut());
              localStorage.clear();
              navigate('/auth/sign-in', { replace: true });
              showToast({ message: 'Your session has expired for security reasons. Please log in again to continue.' });
              return; // Exit early if user doesn't match
            }

            if ((authToken.user.email === isUserMatch.email) && resStatus !== 200) {
              dispatch(userSignOut());
              navigate('/auth/sign-in', { replace: true });
              showToast({ message: 'Your session has expired for security reasons. Please log in again to continue.' });
              return; // Exit early if user doesn't match
            }
          }
          setAuthChecked(true);
        } else if (isTokenExpired) {
          dispatch(userSignOut());
          navigate('/auth/sign-in', { replace: true });
          showToast({ message: 'Your session has expired for security reasons. Please log in again to continue.' });
        } else {
          // TODO: Toast user invalid credentials and let them sign in again
          dispatch(userSignOut());
          navigate('/auth/sign-in', { replace: true });
          showToast({ message: 'Your session has expired for security reasons. Please log in again to continue.' });
        }
      } catch (err) {
        // TODO: Customize Error Message
        console.error(err);
        dispatch(userSignOut());
        navigate('/auth/sign-in', { replace: true });
        showToast({ message: 'Your session has expired for security reasons. Please log in again to continue.' });
      } finally {
        setLoading(false);
      }
    };

    verifyUser();
  }, [dispatch, navigate, setAuthChecked]);

  return { authChecked, loading };
}
