/* eslint-disable import/no-cycle */
import { apiSlice } from '../api/apiSlice';
import { userSignIn, userSignOut } from './authSlice';

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (data) => ({
        url: '/auth/signup',
        method: 'POST',
        body: data,
      }),
    }),
    signIn: builder.mutation({
      query: (data) => ({
        url: '/auth/signin',
        method: 'POST',
        body: data,
      }),
      extraOptions: { maxRetries: 0 },
      // update localStorage and redux store with rtk asynchronous function
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: result } = await queryFulfilled;
          const authInfo = {
            accessToken: result.accessToken,
            refreshToken: result.refreshToken,
            user: result.user,
          };
          // update to localStorage
          localStorage.setItem('auth', JSON.stringify(authInfo));
          // using dispatch to update redux store with auth information
          dispatch(userSignIn(authInfo));
        } catch (err) {
          localStorage.removeItem('auth');
          dispatch(userSignOut());
        }
      },
    }),
    verifyEmail: builder.mutation({
      query: (data) => ({
        url: '/auth/verify-email',
        method: 'POST',
        body: data,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    sendVerificationEmail: builder.mutation({
      query: (data) => ({
        url: '/auth/send-verification-email',
        method: 'POST',
        body: data,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: data,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    resetForgotPassword: builder.mutation({
      query: (data) => ({
        url: '/auth/reset-forgot-password',
        method: 'POST',
        body: data,
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: '/auth/change-password',
        method: 'POST',
        body: data,
      }),
    }),
    refreshToken: builder.mutation({
      query: (data) => ({
        url: '/auth/token/refresh',
        method: 'POST',
        body: data,
      }),
    }),
    getCurrentUser: builder.query({
      query: () => ({
        url: '/auth/get-current-user',
        method: 'GET',
      }),
      providesTags: ['CurrentUser'],
      invalidatesTags: ['CurrentUser'],
    }),
  }),
});

export const {
  useSignUpMutation,
  useSignInMutation,
  useVerifyEmailMutation,
  useSendVerificationEmailMutation,
  useResetPasswordMutation,
  useResetForgotPasswordMutation,
  useChangePasswordMutation,
  useRefreshTokenMutation,
  useGetCurrentUserQuery,
} = authApi;
