import ContentLoader from 'components/ui/ContentLoader';
import { useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuthCheck from '../../hooks/useAuthCheck';

export default function AuthGuard({ children }) {
  const { authChecked, loading } = useAuthCheck();
  const location = useLocation();

  const handleNavigation = useCallback(() => {
    if (loading) {
      return <ContentLoader />;
    } if (!authChecked) {
      return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;
    }
    return children;
  }, [authChecked, children, loading, location]);

  return handleNavigation();
}
