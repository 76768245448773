import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BusinessIcon from '@mui/icons-material/Business';
import ContactsIcon from '@mui/icons-material/Contacts';
import Diversity2Icon from '@mui/icons-material/Diversity2';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import Diversity3Icon from '@mui/icons-material/Diversity3';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import FacebookIcon from '@mui/icons-material/Facebook';
// import GoogleIcon from '@mui/icons-material/Google';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PaidIcon from '@mui/icons-material/Paid';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PixIcon from '@mui/icons-material/Pix';
import PlumbingIcon from '@mui/icons-material/Plumbing';
// import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ReactComponent as GroupIcon } from 'assets/Group.svg';
// import { ReactComponent as AppointmentIcon } from "assets/appointment.svg";
import { ReactComponent as ChatIcon } from 'assets/chat.svg';
import { ReactComponent as DirectoryIcon } from 'assets/directory.svg';
// import { ReactComponent as LeadGenerationIcon } from "assets/lead-generation.svg";
import { ReactComponent as SeoIcon } from 'assets/seo.svg';
// import { ReactComponent as SupportTicketIcon } from "assets/support-ticket.svg";
import ErrorIcon from '@mui/icons-material/Error';
import styled from 'styled-components';

const Group = styled(GroupIcon)`
  width: 24px;
  height: 24px;
`;

const Chat = styled(ChatIcon)`
  width: 24px;
  height: 24px;
`;

const Seo = styled(SeoIcon)`
  width: 24px;
  height: 24px;
`;

const Directory = styled(DirectoryIcon)`
  width: 24px;
  height: 24px;
`;

// const LeadGeneration = styled(LeadGenerationIcon)`
//   width: 24px;
//   height: 24px;
// `;

// const Appointment = styled(AppointmentIcon)`
//   width: 24px;
//   height: 24px;
// `;

// const SupportTicket = styled(SupportTicketIcon)`
//   width: 24px;
//   height: 24px;
// `;

const pagesSection = [
  {
    href: '/reputation-management',
    icon: Group,
    title: 'Reputation Management',
  },
  {
    href: '/conversations',
    icon: Chat,
    title: 'Conversations',
    children: [
      {
        href: '/conversations/facebook/select',
        icon: FacebookIcon,
        title: 'Facebook',
      },
      // {
      //   href: '/conversations/instagram/select',
      //   icon: InstagramIcon,
      //   title: 'Instagram',
      // },
      // {
      //   href: '/conversations/google/select',
      //   icon: GoogleIcon,
      //   title: 'Google',
      // },
      // {
      //   href: '/conversations/whatsapp/select',
      //   icon: WhatsAppIcon,
      //   title: 'Whatsapp',
      // },
    ],
  },
  {
    href: '/local-seo',
    icon: Seo,
    title: 'Local SEO',
    // badge: "8",
  },
  {
    href: '/contact-management',
    icon: Directory,
    title: 'Contact Management',
    children: [
      {
        href: '/contact-management/all-contact',
        icon: ContactsIcon,
        title: 'All Contact',
      },
      {
        href: '/contact-management/my-contact',
        icon: PermContactCalendarIcon,
        title: 'My Contact',
      },
    ],
  },
  {
    href: '/businesses',
    icon: BusinessIcon,
    title: 'Business Management',
    children: [
      {
        href: '/businesses/all',
        icon: DomainAddIcon,
        title: 'Manage Business',
      },
      {
        href: '/businesses/team',
        icon: Diversity2Icon,
        title: 'Manage Team',
      },
    ],
  },
  // {
  //   href: "/lead-management",
  //   icon: LeadGeneration,
  //   title: "Lead Management",
  //   children: [
  //     {
  //       href: "/lead-management/leads",
  //       icon: Diversity3Icon,
  //       title: "Leads",
  //     },
  //     {
  //       href: "/lead-management/lead-prospects",
  //       icon: QueryStatsIcon,
  //       title: "Lead Prospects",
  //     },
  //   ],
  // },
  // {
  //   href: "/appointments",
  //   icon: Appointment,
  //   title: "Appointments",
  // },
  // {
  //   href: "/support-ticket",
  //   icon: SupportTicket,
  //   title: "Support Ticket",
  // },
  {
    href: '/subscription-packages',
    icon: PixIcon,
    title: 'My Subscriptions',
  },
  {
    href: '/error-code',
    icon: ErrorIcon,
    title: 'Error Code',
  },
];

const pagesSectionForNewUser = [
  {
    href: '/subscription-packages',
    icon: PixIcon,
    title: 'My Subscriptions',
  },
];

const roleSection = [
  {
    href: '/role',
    icon: VerifiedUserIcon,
    title: 'Role Management',
    children: [
      {
        href: '/role/all',
        icon: ManageAccountsIcon,
        title: 'Manage Roles',
      },
    ],
  },
];

const usersSection = [
  {
    href: '/user-management',
    icon: AccountCircleIcon,
    title: 'User Management',
    children: [
      {
        href: '/user-management/admins',
        icon: AdminPanelSettingsIcon,
        title: 'Manage Admins',
      },
      {
        href: '/user-management/users',
        icon: PeopleAltIcon,
        title: 'Manage Users',
      },
    ],
  },
];

const packagesSection = [
  {
    href: '/packages',
    icon: PixIcon,
    title: 'Package Management',
    children: [
      {
        href: '/packages/all',
        icon: PlumbingIcon,
        title: 'Manage Packages',
      },
    ],
  },
];

const paymentSection = [
  {
    href: '/payment',
    icon: PaidIcon,
    title: 'Payment Methods',
    children: [
      {
        href: '/payment/all',
        icon: AccountBalanceWalletIcon,
        title: 'Manage Payment Methods',
      },
    ],
  },
];

export const navItems = [
  {
    title: 'Pages',
    pages: pagesSection,
  },
  {
    title: 'Roles',
    pages: roleSection,
  },
  {
    title: 'Users',
    pages: usersSection,
  },
  {
    title: 'Packages',
    pages: packagesSection,
  },
  {
    title: 'Payment',
    pages: paymentSection,
  },
];

export const navItemsForNewUser = [
  {
    title: 'Pages',
    pages: pagesSectionForNewUser,
  },
  {
    title: 'Users',
    pages: usersSection,
  },
  {
    title: 'Packages',
    pages: packagesSection,
  },
  {
    title: 'Payment',
    pages: paymentSection,
  },
];

export const navItemsForAdmin = [
  {
    title: 'Roles',
    pages: roleSection,
  },
  {
    title: 'Users',
    pages: usersSection,
  },
  {
    title: 'Packages',
    pages: packagesSection,
  },
];

export const navItemsForNoCompany = [
  {
    title: 'Users',
    pages: usersSection,
  },
  {
    title: 'Packages',
    pages: packagesSection,
  },
];
