import {
  Box, Button, Stack, TableBody, Typography,
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import amexLogo from 'assets/amex.png';
import mastercardLogo from 'assets/mastercard.png';
import visaLogo from 'assets/visa-blue.png';
import StyledTable from 'components/ui/common/table/StyledTable';
import StyledTableCell from 'components/ui/common/table/StyledTableCell';
import StyledTableContainer from 'components/ui/common/table/StyledTableContainer';
import {
  sxTableHeadBG,
} from '../../theme/globalStyles';

export default function CardsTable({ cardData, initiateCardHandler, isCardInitiating }) {
  const {
    cardBrand, last4Digits, month, year,
  } = cardData;
  return (
    !cardData || cardBrand === null ? (
      <Box>
        <Stack direction="column" gap={2} alignItems="center">
          <Typography variant="p" component="p" sx={{ fontWeight: 'bold' }}>No card have been added yet</Typography>
          <Button onClick={() => initiateCardHandler()} variant="contained">{isCardInitiating ? 'Redirecting...' : 'Add Card'}</Button>
        </Stack>
      </Box>
    ) : (
      <StyledTableContainer>
        <StyledTable>
          <TableHead sx={{ ...sxTableHeadBG }}>
            <TableRow>
              <StyledTableCell>Card Number</StyledTableCell>
              <StyledTableCell>Expiry Date</StyledTableCell>
              <StyledTableCell>Card Brand</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>{`**** **** **** ${last4Digits}`}</StyledTableCell>
              <StyledTableCell>{`${month}/${year}`}</StyledTableCell>
              <StyledTableCell>
                {cardBrand === 'visa' ? <img style={{ width: '50px' }} src={visaLogo} alt="visa__logo" /> : cardBrand === 'amex' ? (
                  <img
                    style={{ width: '50px' }}
                    src={amexLogo}
                    alt="amex__logo"
                  />
                ) : cardBrand === 'mastercard' ? <img style={{ width: '50px' }} src={mastercardLogo} alt="mastercard__logo" /> : cardBrand}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    )
  );
}
