export const API_URL = process.env.REACT_APP_API_URL;
export const APP_NAME = '%s | Orbit Genie';
export const APP_FULL_NAME = 'Orbit Genie';
export const APP_TITLE = 'Orbit Genie- Admin';

export const SECRET_KEY = 'c19eb3b8366b0dcc78ff561ec288945cd759d3af174c9915f078cdda96c11584';

// Themes
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO',
};

// role
export const ROLE_DELETE = 'ROLE DELETE';
export const ROLE_UPDATE = 'ROLE UPDATE';
export const ROLE_CREATE = 'ROLE CREATE';
export const ROLE_VIEW = 'ROLE VIEW';

// user
export const USER_DELETE = 'USER DELETE';
export const USER_UPDATE = 'USER UPDATE';
export const USER_CREATE = 'USER CREATE';
export const USER_VIEW = 'USER VIEW';

// company
export const COMPANY_DELETE = 'COMPANY DELETE';
export const COMPANY_UPDATE = 'COMPANY UPDATE';
export const COMPANY_CREATE = 'COMPANY CREATE';
export const COMPANY_VIEW = 'COMPANY VIEW';

// package
export const PACKAGE_DELETE = 'PACKAGE DELETE';
export const PACKAGE_UPDATE = 'PACKAGE UPDATE';
export const PACKAGE_CREATE = 'PACKAGE CREATE';
export const PACKAGE_VIEW = 'PACKAGE VIEW';

// ecode
export const ECODE_DELETE = 'ECODE DELETE';
export const ECODE_UPDATE = 'ECODE UPDATE';
export const ECODE_CREATE = 'ECODE CREATE';
export const ECODE_VIEW = 'ECODE VIEW';

// business
export const BUSINESS_DELETE = 'BUSINESS DELETE';
export const BUSINESS_UPDATE = 'BUSINESS UPDATE';
export const BUSINESS_CREATE = 'BUSINESS CREATE';
export const BUSINESS_VIEW = 'BUSINESS VIEW';

// payment
// export const PAYMENT_DELETE = "PAYMENT DELETE";
export const PAYMENT_UPDATE = 'PAYMENT UPDATE';
// export const PAYMENT_CREATE = "PAYMENT CREATE";
// export const PAYMENT_VIEW = "PAYMENT VIEW";

// channel
export const CHANNEL_DELETE = 'CHANNEL DELETE';
export const CHANNEL_UPDATE = 'CHANNEL UPDATE';
export const CHANNEL_CREATE = 'CHANNEL CREATE';
export const CHANNEL_VIEW = 'CHANNEL VIEW';

// social media
export const SOCIAL_MEDIA_UPDATE = 'SOCIALMEDIA UPDATE';
export const SOCIAL_MEDIA_VIEW = 'SOCIALMEDIA VIEW';

// contact
export const CONTACT_DELETE = 'CONTACT DELETE';
export const CONTACT_UPDATE = 'CONTACT UPDATE';
export const CONTACT_CREATE = 'CONTACT CREATE';
export const CONTACT_VIEW = 'CONTACT VIEW';

// message
export const MESSAGE_DELETE = 'MESSAGE DELETE';
export const MESSAGE_SEND = 'MESSAGE SEND';
export const MESSAGE_VIEW = 'MESSAGE VIEW';
