import CreateIcon from '@mui/icons-material/Create';
import {
  Box, Button, Card, Stack, Typography,
} from '@mui/material';
import CardsTable from 'components/table/CardsTable';
import ContentLoader from 'components/ui/ContentLoader';
import ErrorAlert from 'components/ui/ErrorAlert';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUserQuery } from 'redux/slices/auth/authApi';
import { selectAuth } from 'redux/slices/auth/authSelector';
import { useGetCardQuery, useInitiateCardMutation } from 'redux/slices/company/companyApi';
import { PAYMENT_UPDATE } from 'utils/constants';
import { sxCustomSpacing } from '../../theme/globalStyles';

export default function AllPayment() {
  const { user } = useSelector(selectAuth);
  const [initiateCard, {
    data: initiateCardData,
    error: initiateCardError, isLoading: isCardInitiating,
  }] = useInitiateCardMutation();
  const {
    data: cardData, isLoading, isError, error,
  } = useGetCardQuery(undefined, { skip: user?.role?.roleType === 'ADMIN' });
  const {
    data: currentUserData, isLoading: isUserLoading, isError: isUserError, error: userError,
  } = useGetCurrentUserQuery();
  const navigate = useNavigate();

  const CAN_UPDATE_PAYMENT = user?.role?.privileges
    ?.some((privilege) => privilege?.descName === PAYMENT_UPDATE);

  const initiateCardHandler = () => {
    initiateCard();
  };

  useEffect(() => {
    if (initiateCardData || initiateCardError) {
      window.open(initiateCardError.data, '_blank');
    }
  }, [initiateCardData, initiateCardError, navigate]);

  // let's decide what to render
  let content = null;

  if (isLoading) {
    content = <ContentLoader />;
  } else if (!isLoading && isError) {
    content = <ErrorAlert title={error?.data?.error || 'Something went wrong, Internal server error'} />;
  } else if (!isLoading && !isError && Object.keys(cardData)?.length === 0) {
    content = <ErrorAlert title="No Data found! Please insert first" />;
  } else if (!isLoading && !isError && Object.keys(cardData)?.length > 0) {
    content = (
      <CardsTable
        cardData={cardData}
        initiateCardHandler={initiateCardHandler}
        isCardInitiating={isCardInitiating}
      />
    );
  }

  // let's decide what to render
  let isUserHasCompany = null;

  if (isUserLoading) {
    isUserHasCompany = <ContentLoader />;
  } else if (!isUserLoading && isUserError) {
    isUserHasCompany = <ErrorAlert title={userError?.data?.error || 'Something went wrong, Internal server error'} />;
  } else if (!isUserLoading && !isUserError && Object.keys(currentUserData)?.length === 0) {
    isUserHasCompany = <ErrorAlert title="No Data found! Please insert first" />;
  } else if (!isUserLoading && !isUserError && Object.keys(currentUserData)?.length > 0) {
    isUserHasCompany = !!currentUserData?.company;
  }

  return (
    <>
      <Helmet title="Payment Methods" />
      {
        isUserHasCompany ? (
          <Box sx={{ ...sxCustomSpacing }}>
            <Stack
              direction="row"
              gap={3}
              alignItems="center"
              justifyContent="end"
              mb={6}
            >
              {CAN_UPDATE_PAYMENT ? (
                <Button
                  variant="contained"
                  onClick={() => initiateCardHandler()}
                >
                  {isCardInitiating ? 'Redirecting...' : (
                    <>
                      <CreateIcon sx={{ mr: 1 }} />
                      {' '}
                      Add New Card
                    </>
                  )}
                </Button>
              ) : null}
            </Stack>
            {/* card information */}
            <Box mt={4} mb={4}>
              <Box mt={2}>
                {content}
              </Box>
            </Box>
          </Box>
        ) : (
          <Card sx={{ p: 4 }}>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>You need to create a Company first to add payment methods</Typography>
          </Card>
        )
      }

    </>
  );
}
