import { apiSlice } from '../api/apiSlice';

export const companyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // admin
    getAllCompany: builder.query({
      query: () => ({
        url: '/company',
        method: 'GET',
      }),
    }),
    // user
    createCompany: builder.mutation({
      query: (data) => ({
        url: '/company',
        method: 'POST',
        body: data,
      }),
    }),
    // user
    updateCompany: builder.mutation({
      query: (data) => ({
        url: '/company',
        method: 'PUT',
        body: data,
      }),
    }),
    // admin
    deleteCompany: builder.mutation({
      query: (id) => ({
        url: `/company?companyId=${id}`,
        method: 'DELETE',
      }),
    }),
    // get card
    getCard: builder.query({
      query: () => ({
        url: '/company/card',
        method: 'GET',
      }),
    }),
    // initiate card
    initiateCard: builder.mutation({
      query: () => ({
        url: '/company/card/add/initiate',
        method: 'POST',
      }),
    }),
    // add initiated card
    addCard: builder.mutation({
      query: (data) => ({
        url: '/company/card/add',
        method: 'POST',
        body: data,
      }),
    }),
    // subscribe package
    subscribePackage: builder.mutation({
      query: (data) => ({
        url: '/company/pack/subscribe/user',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Packages', 'CurrentUser'],
    }),
    // subscribe package by admin for a certain business
    subscribePackageByAdmin: builder.mutation({
      query: (data) => ({
        url: '/company/pack/subscribe/admin',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Packages'],
    }),
    // additional user purchase
    additionalUserPurchase: builder.mutation({
      query: (data) => ({
        url: '/company/pack/additional-user-purchase',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Packages'],
    }),
  }),
});

export const {
  useGetAllCompanyQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCardQuery,
  useLazyGetCardQuery,
  useInitiateCardMutation,
  useAddCardMutation,
  useSubscribePackageMutation,
  useSubscribePackageByAdminMutation,
  useAdditionalUserPurchaseMutation,
} = companyApi;
