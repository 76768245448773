/* eslint-disable no-unused-vars */
import { isRejectedWithValue } from '@reduxjs/toolkit';
import showToast from 'utils/custom-toast';
import { userSignOut } from './slices/auth/authSlice';
// eslint-disable-next-line import/no-cycle
import { store } from './store';

/**
 * Log a warning and show a toast!
 */
const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // showToast({
    //   title: 'An Error Occurred!',
    //   message: 'data' in action.error ? (action.error.data.message || action.error.data.errorMessage) : action.payload.data.errorMessage,
    //   type: 'failure',
    // });

    if (action?.payload?.status === 403) {
      // Todo: need to show a toast while logout
      store.dispatch(userSignOut());
      showToast({ message: 'Your session has expired for security reasons. Please log in again to continue.' });
    }
  }

  return next(action);
};

export default rtkQueryErrorLogger;
