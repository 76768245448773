/* eslint-disable import/prefer-default-export */
import { ResetPasswordEntry, VerifyEmail } from 'components/auth';
import ContentLoader from 'components/ui/ContentLoader';
import { AuthLayout, ConversationLayout, DashboardLayout } from 'layouts';
import {
  Page404, ResetPassword, SignIn, SignUp,
} from 'pages/auth';
import FacebookConversationSelect from 'pages/conversations/facebook/FacebookConversationSelect';
import { AddCardFailure, AddCardSuccess } from 'pages/payment';
import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import AdminRoute from 'routes/adminRoute/AdminRoute';
import PublicRoute from 'routes/publicRoute/PublicRoute';
import AuthGuard from '../guards/AuthGuard';

// lazy imports
const Home = lazy(() => import('pages/pages/home/Home'));

// auth pages
// const SignIn = lazy(() => import('pages/auth/SignIn'));
// const SignUp = lazy(() => import('pages/auth/SignUp'));
// const ResetPassword = lazy(() => import('pages/auth/ResetPassword'));
// const Page404 = lazy(() => import('pages/auth/Page404'));
// const VerifyEmail = lazy(() => import('components/auth/VerifyEmail'));
// const ResetPasswordEntry = lazy(() => import('components/auth/ResetPasswordEntry'));

// contact management
const AllContact = lazy(() => import('pages/contact-management/AllContact'));
const MyContact = lazy(() => import('pages/contact-management/MyContact'));

// business management
const AllBusinesses = lazy(() => import('pages/business/AllBusinesses'));
const CreateBusiness = lazy(() => import('pages/business/CreateBusiness'));
const EditBusiness = lazy(() => import('pages/business/EditBusiness'));
const ViewTeamUser = lazy(() => import('pages/business/ViewTeamUser'));
const BusinessTeam = lazy(() => import('pages/business/BusinessTeam'));
const CreateNewUser = lazy(() => import('pages/business/CreateNewUser'));

// packages management
const AllPackages = lazy(() => import('pages/packages/AllPackages'));
const CreatePackage = lazy(() => import('pages/packages/CreatePackage'));
const EditPackage = lazy(() => import('pages/packages/EditPackage'));
const SubscriptionPackages = lazy(() => import('pages/packages/SubscriptionPackages'));
const AddToCompany = lazy(() => import('pages/packages/AddToCompany'));
const UserPurchase = lazy(() => import('pages/packages/UserPurchase'));

// role management
const AllRole = lazy(() => import('pages/role/AllRole'));
const CreateRole = lazy(() => import('pages/role/CreateRole'));
const EditRole = lazy(() => import('pages/role/EditRole'));

// error code management
const CreateErrorCode = lazy(() => import('pages/error-code/CreateErrorCode'));
const EditErrorCode = lazy(() => import('pages/error-code/EditErrorCode'));
const ErrorCodeManage = lazy(() => import('pages/error-code/ErrorCodeManage'));

// payment management
const AllPayment = lazy(() => import('pages/payment/AllPayment'));
// const AddCardSuccess = lazy(() => import('pages/payment/AddCard'));
// const AddCardFailure = lazy(() => import('pages/payment/AddCardFailure'));

// admin management
const Admins = lazy(() => import('pages/user-management/admins/Admins'));
const CreateAdmin = lazy(() => import('pages/user-management/admins/CreateAdmin'));
const ViewAdmin = lazy(() => import('pages/user-management/admins/ViewAdmin'));

// user management
const Users = lazy(() => import('pages/user-management/users/Users'));
const ViewUser = lazy(() => import('pages/user-management/users/ViewUser'));

// channel
const EditChannel = lazy(() => import('pages/channels/EditChannel'));

// company
const CreateCompany = lazy(() => import('pages/company/CreateCompany'));

// local seo
const LocalSeo = lazy(() => import('pages/local-seo/LocalSeo'));

// conversation management
const Facebook = lazy(() => import('pages/message/Facebook'));

// others
const Profile = lazy(() => import('pages/user-profile/Profile'));
const BusinessChannelSelection = lazy(() => import('pages/message/BusinessChannelSelection'));
const ReputationViewer = lazy(() => import('pages/reputation-management/ReputationViewer'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    errorElement: (
      <Suspense fallback={<ContentLoader />}>
        <Page404 />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<ContentLoader />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: 'select-inbox',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <BusinessChannelSelection />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'auth',
    element: (
      <PublicRoute>
        <AuthLayout />
      </PublicRoute>
    ),
    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'forgottenPassword',
        element: <ResetPasswordEntry />,
      },
      {
        path: 'verifyEmail',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <VerifyEmail />
          </Suspense>
        ),
      },

    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
  },
  {
    path: 'contact-management',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'all-contact',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <AllContact />
          </Suspense>
        ),
      },
      {
        path: 'my-contact',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <MyContact />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'role',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'all',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <AllRole />
          </Suspense>
        ),
      },
      {
        path: 'create',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <CreateRole />
          </Suspense>
        ),
      },
      {
        path: 'edit/:roleId',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <EditRole />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'reputation-management',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <ReputationViewer />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'create-company',
    element: (
      <AuthGuard>
        <Suspense fallback={<ContentLoader />}>
          <CreateCompany />
        </Suspense>
      </AuthGuard>
    ),
  },
  {
    path: 'local-seo',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <LocalSeo />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'user-profile',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'view',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <Profile />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'user-management',
    element: (
      <AuthGuard>
        <AdminRoute>
          <DashboardLayout />
        </AdminRoute>
      </AuthGuard>
    ),
    children: [
      {
        path: 'admins',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <Admins />
          </Suspense>
        ),
      },
      {
        path: 'admins/:id',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <ViewAdmin />
          </Suspense>
        ),
      },
      {
        path: 'admins/create',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <CreateAdmin />
          </Suspense>
        ),
      },
      {
        path: 'users',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <Users />
          </Suspense>
        ),
      },
      {
        path: 'users/:id',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <ViewUser />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'packages',
    element: (
      <AuthGuard>
        <AdminRoute>
          <DashboardLayout />
        </AdminRoute>
      </AuthGuard>
    ),
    children: [
      {
        path: 'all',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <AllPackages />
          </Suspense>
        ),
      },
      {
        path: 'create',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <CreatePackage />
          </Suspense>
        ),
      },
      {
        path: 'edit/:id',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <EditPackage />
          </Suspense>
        ),
      },
      {
        path: 'add-to-company',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <AddToCompany />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'businesses',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'all',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <AllBusinesses />
          </Suspense>
        ),
      },
      {
        path: 'create',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <CreateBusiness />
          </Suspense>
        ),
      },
      {
        path: 'edit/:id',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <EditBusiness />
          </Suspense>
        ),
      },
      {
        path: 'team',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <BusinessTeam />
          </Suspense>
        ),
      },
      {
        path: 'team/create-user',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <CreateNewUser />
          </Suspense>
        ),
      },
      {
        path: 'team/users/:id',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <ViewTeamUser />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'payment',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'all',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <AllPayment />
          </Suspense>
        ),
      },
      {
        path: 'add-card/success',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <AddCardSuccess />
          </Suspense>
        ),
      },
      {
        path: 'add-card/failure',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <AddCardFailure />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'subscription-packages',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <SubscriptionPackages />
          </Suspense>
        ),
      },
      {
        path: 'user-purchase',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <UserPurchase />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/channels',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ':id',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <EditChannel />
          </Suspense>
        ),
      },
    ],
  },
  // conversations
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'conversations/facebook/select',
        element: <FacebookConversationSelect />,
      },
    ],
  },
  {
    path: 'conversations',
    element: (
      <AuthGuard>
        <ConversationLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'facebook',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <Facebook />
          </Suspense>
        ),
      },
    ],
  },
  // error code Management page
  {
    path: 'error-code',
    element: (
      <AuthGuard>
        <AdminRoute>
          <DashboardLayout />
        </AdminRoute>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <ErrorCodeManage />
          </Suspense>
        ),
      },
      {
        path: ':id',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <EditErrorCode />
          </Suspense>
        ),
      },
      {
        path: 'create',
        element: (
          <Suspense fallback={<ContentLoader />}>
            <CreateErrorCode />
          </Suspense>
        ),
      },
    ],
  },
]);
