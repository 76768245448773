import { apiSlice } from '../api/apiSlice';

export const businessApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBusinesses: builder.query({
      query: () => ({
        url: '/business',
        method: 'GET',
      }),
      providesTags: ['Businesses'],
    }),
    getBusiness: builder.query({
      query: (id) => ({
        url: `/business/id/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        'Business',
        { type: 'Business', id: arg },
      ],
    }),
    createBusiness: builder.mutation({
      query: (data) => ({
        url: '/business',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Businesses'],
    }),
    updateBusiness: builder.mutation({
      query: (data) => ({
        url: '/business',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        'Businesses',
        { type: 'Business', id: arg.businessId },
      ],
    }),
    deleteBusiness: builder.mutation({
      query: (id) => ({
        url: `/business/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Businesses'],
    }),
  }),
});

export const {
  useGetBusinessesQuery,
  useGetBusinessQuery,
  useCreateBusinessMutation,
  useUpdateBusinessMutation,
  useDeleteBusinessMutation,
} = businessApi;
