/* eslint-disable import/no-extraneous-dependencies */
import { Report } from 'notiflix/build/notiflix-report-aio';
import { toast } from 'sonner';

Report.init({ svgSize: '55px' });

/**
 * ${1:Description placeholder}
 * @date 2/4/2024 - 12:09:29 PM
 *
 * @export
 * @param {string} title
 * @param {string} message
 * @param {string} [button='Okay']
 * @param {string} [type='success']
 */
export default function showToast({
  title = '', message = '', button = 'Okay', type = 'success',
}) {
  switch (type) {
    case 'success':
      return toast.success(message);
    case 'failure':
      return Report[type](title, message, button);
    case 'warning':
      return Report[type](title, message, button);
    case 'info':
      return toast.info(message);
    default:
      return toast(message);
  }
}
