/* eslint-disable import/no-cycle */
import { useLocation } from 'react-router-dom';
import reduceChildRoutes from './reduceChildRoutes';

function SidebarNavList(props) {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({
      items, page, currentRoute, depth,
    }),
    [],
  );

  // Wrap child routes with additional styling if depth indicates they are children
  const styledChildRoutes = childRoutes.map((childRoute, index) => {
    // Adjust the condition based on how you determine a child route, e.g., depth > 0
    if (depth > 0) {
      return (
        <div key={index} style={{ marginLeft: '20px' }}>
          {' '}
          {/* Adjust marginLeft as needed */}
          {childRoute}
        </div>
      );
    }
    return childRoute; // Return the route without additional wrapping if not a child
  });

  return styledChildRoutes;
}

export default SidebarNavList;
