import { useLocation } from 'react-router-dom';

const routeToTitleMap = {
  '/reputation-management': 'Reputation Management',
  '/message/facebook': 'Facebook',
  '/message/google': 'Google',
  '/message/whatsapp': 'WhatsApp',
  '/role/all': 'Manage Roles',
  '/local-seo': 'Local SEO',
  '/contact-management/all-contact': 'All Contact',
  '/contact-management/my-contact': 'My Contact',
  '/lead-management/leads': 'Leads',
  '/lead-management/lead-prospects': 'Lead Prospects',
  '/appointments': 'Appointments',
  '/support-ticket': 'Support Ticket',
  '/role/create': 'Create Role',
  '/user-profile/view': 'User Profile',
  '/user-management/admins': 'Manage Admins',
  '/user-management/users': 'Manage Users',
  '/user-management/admins/create': 'Create Admin',
  '/packages/all': 'Manage Packages',
  '/packages/create': 'Create Package',
  '/packages/add-to-company': 'Add Package',
  '/businesses/all': 'Manage Business',
  '/businesses/create': 'Create Business',
  '/businesses/team': 'Manage Team',
  '/payment/all': 'Payment Methods',
  '/payment/add-card/success': 'Add Card',
  '/payment/add-card/failure': 'Add Card',
  '/subscription-packages': 'Subscription Packages',
  '/subscription-packages/user-purchase': 'User Purchase',
  '/select-inbox': 'Select Inbox',
  '/error-code': 'Error Code Manage',
  '/dashboard': 'Dashboard',
  '/': 'Orbit Genie',
  // Add more entries for other routes as needed
};

// Identifying potential dynamic IDs
const isPotentialDynamicId = (segment) => /^[a-zA-Z0-9_-]+$/.test(segment);
const getTitleForDynamicRoute = (segments) => {
  // Construct a title based on specific segments using a switch statement
  const routeKey = segments.slice(1, -1).join('/');

  switch (routeKey) {
    case 'user-management/admins':
      return 'Admin Details';
    case 'user-management/users':
      return 'User Details';
    case 'packages/edit':
      return 'Edit Package';
    case 'role/edit':
      return 'Edit Role';
      // Add more cases here as needed
    default:
      // Fallback for unhandled dynamic routes
      return routeKey?.split('/').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
};

const GetTitleFromRoute = (route) => {
  // Check for static routes
  if (routeToTitleMap[route]) {
    return routeToTitleMap[route];
  }

  // Handling potential dynamic routes
  const segments = route?.split('/');
  if (segments.length > 2 && isPotentialDynamicId(segments[segments.length - 1])) {
    return getTitleForDynamicRoute(segments);
  }

  return 'Not Found';
};

export const IsCurrentRouteSameAsParameter = (routeName) => {
  const location = useLocation();
  return location.pathname.toString().toLowerCase() === routeName.toLowerCase();
};

export const IsCurrentRouteSameAsOneOfTheRoutes = (routeNameList) => {
  const location = useLocation();
  if (!Array.isArray(routeNameList)) {
    console.error('Invalid argument: routeNameList must be an array.');
    return false;
  }
  const currentPath = location.pathname.toLowerCase();

  // Check if any route in the array matches the current route
  return routeNameList.some((route) => currentPath.startsWith(route.toLowerCase()));
};

export default GetTitleFromRoute;
