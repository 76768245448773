import {
  Box, CircularProgress, Fade, Stack,
} from '@mui/material';
import orbitGenieLogo from 'assets/rebranding/orbitgenie.png';

export default function ContentLoader() {
  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center', backgroundColor: 'background.default',
    }}
    >
      <Fade in>
        <Stack alignItems="center" justifyContent="center">
          <img src={orbitGenieLogo} alt="orbit genie" width={150} />
          <CircularProgress
            color="primary"
            sx={{
              my: 4,
            }}
          />
        </Stack>
      </Fade>
    </Box>
  );
}
