import AuthProvider from 'contexts/AuthProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import 'react-quill/dist/quill.snow.css';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { store } from './redux/store';
import { APP_NAME, APP_TITLE } from './utils/constants';

const titleTemplate = APP_NAME;
const defaultTitle = APP_TITLE;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle} />
      <Provider store={store}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
);
