import { TableCell, styled } from '@mui/material';

const Styledcell = styled(TableCell)(() => ({
  padding: '0.45rem',
  '&:first-of-type': {
    paddingLeft: '0.75rem',
  },
}));

export default function StyledTableCell({ children, ...props }) {
  return (
    <Styledcell {...props}>
      {children}
    </Styledcell>
  );
}
