import { Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

import {
  Alert,
  Button,
  TextField,
} from '@mui/material';
import showToast from 'utils/custom-toast';
import { useResetPasswordMutation } from '../../redux/slices/auth/authApi';

function ResetPassword() {
  // const [error, setError] = useState("");
  const [resetPassword, { data, error: resetPasswordError }] = useResetPasswordMutation();

  useEffect(() => {
    if (data?.isMailSent) {
      showToast({ message: 'An email has been sent to your email address, please check your inbox', type: 'success' });
    } else if (!data?.isMailSent && resetPasswordError) {
      showToast({ title: 'Reset Password', message: resetPasswordError?.data?.errorMessage || 'Entered email is not found, Please enter a valid email', type: 'failure' });
    }
  }, [data, resetPasswordError]);

  return (
    <Formik
      initialValues={{
        email: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
      })}
      onSubmit={async (
        values,
        {
          resetForm,
        },
      ) => {
        console.log(values.email);
        resetPassword({ email: values.email });
        resetForm();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
            InputLabelProps={{ style: { fontSize: '0.825rem' } }}
            inputProps={{ style: { fontSize: '0.825rem' } }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{
              // textTransform: "capitalize",
              // bgcolor: "#3b757f",
              // "&:hover": { bgcolor: "#356169" },
              mt: 2,
            }}
          >
            Reset password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
