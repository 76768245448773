import {
  Box,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { APP_FULL_NAME } from '../utils/constants';

function Footer() {
  return (
    <Box px={2} py={5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {/* Company Info */}
        <Typography>{`© ${new Date().getFullYear()} - ${APP_FULL_NAME}`}</Typography>
        {/* Developer Info */}
        <Typography fontSize="0.875rem" color="grey">
          Developed and Maintained by
          {' '}
          <Link fontWeight={600} href="https://dhrubokinfotech.com/" target="_blank">Dhurbok Infotech Services Limited</Link>
        </Typography>
      </Stack>
    </Box>
  );
}

export default Footer;
