import { Box, Paper, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SignUpComponent from '../../components/auth/SignUp';
import logo from '../../vendor/logo.svg';

function SignUp() {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 4,
            }}
          >
            {/* <Brand /> */}
            <img src={logo} alt="logo" width={200} />
          </Box>
          {/* <Wrapper> */}
          <Helmet title="Sign Up" />
          <Paper
            sx={{
              p: 6,
              width: '32rem',
              height: 'auto',
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;',
            }}
          >
            <Box mb={6}>
              <Typography
                component="h5"
                variant="h5"
                fontWeight="bold"
                fontSize="1.15rem"
                align="center"
                gutterBottom
                textTransform="capitalize"
              >
                Get started
              </Typography>
              <Typography
                component="p"
                variant="body1"
                align="center"
                fontSize="0.825rem"
              >
                Start creating the best possible user experience for you
                customers
              </Typography>
            </Box>

            <SignUpComponent />
          </Paper>
        </Box>
      </Box>
      {/* </Wrapper> */}
    </>
  );
}

export default SignUp;
