import {
  Alert, Box, Button, LinearProgress, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useVerifyEmailMutation } from 'redux/slices/auth/authApi';
import showToast from 'utils/custom-toast';

export default function VerifyEmail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const [error, setError] = useState('');

  useEffect(() => {
    const verify = async () => {
      if (token) {
        try {
          await verifyEmail({ token }).unwrap();
          showToast({ message: 'Email verified successfully', type: 'success' });
          navigate('/auth/sign-in');
        } catch (error) {
          const errorMessage = error?.data?.errorMessage || 'Email verification failed, Please try again later';
          setError(errorMessage);
          showToast({ title: 'Email verification failed', message: errorMessage, type: 'failure' });
        } finally {
          navigate('/auth/sign-in');
        }
      } else {
        setError('No verification token found. Please use the link provided in your verification email.');
      }
    };

    verify();
  }, [token, verifyEmail, navigate]);

  if (isLoading) {
    return (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="h5" component="h2" mb={2}>Verifying Your Email...</Typography>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      {error ? (
        <>
          <Alert severity="error">{error}</Alert>
          <Button onClick={() => navigate('/auth/sign-in')} variant="contained" sx={{ mt: 2 }}>Return to Sign In</Button>
        </>
      ) : (
        <Typography variant="h5" component="h2">Your email has been successfully verified.</Typography>
      )}
    </Box>
  );
}
