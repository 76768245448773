import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import {
  Box,
  Collapse, Fade, FormControl,
  Grow, InputLabel, ListItemIcon, ListItemText, MenuItem, Select,
  Stack, TextField, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Skeleton from '@mui/material/Skeleton';
import ErrorAlert from 'components/ui/ErrorAlert';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from 'redux/slices/auth/authSelector';
import { useGetBusinessesQuery } from 'redux/slices/business/businessApi';
import { useGetChannelsQuery } from 'redux/slices/channel/channelApi';
import { setBusiness, setChannel, setChannelId } from 'redux/slices/conversation/conversationSlice';

export default function BusinessChannelSelectorBar() {
  const { user } = useSelector(selectAuth);
  const { business, channel } = useSelector((state) => state.conversation);
  const [businessId, setBusinessId] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const { data: businesses } = useGetBusinessesQuery(undefined, { skip: user?.role?.roleType === 'ADMIN' });
  const {
    data: channels, isLoading, isError, error,
  } = useGetChannelsQuery(businessId, { skip: !businessId });
  // const [newBusiness, setNewBusiness] = useState(null);
  const [channelSelectVisible, setChannelSelectVisible] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const parsedBusiness = JSON.parse(e.target.value);
    setBusinessId(parsedBusiness?.id);
    dispatch(setBusiness(parsedBusiness));
    setChannelSelectVisible(true);

    // Reset channel data and hide channel select when a new business is selected
    setChannelData(null);
    setChannelSelectVisible(false);
  };

  const renderChannelSelect = () => {
    const showSkeleton = businessId && isLoading;

    if (showSkeleton) {
      // Show skeleton loader while loading
      return (
        <Fade in={showSkeleton} timeout={500}>
          <Skeleton variant="rectangular" width={210} height={56} />
        </Fade>
      );
    }

    if (!channelSelectVisible) {
      return null;
    }

    if (isError) {
      return <ErrorAlert title={error?.data?.error || 'Something went wrong, Internal server error'} />;
    }

    if (channels?.length === 0) {
      return null;
    }

    return (
      <Collapse in={!showSkeleton} orientation="horizontal">
        {/* <FormControl sx={{ width: '12rem' }} disabled={!businessId}>
          <InputLabel id="select-active">Select Channel</InputLabel>
          <Select
            id="channel"
            name="channel"
            labelId="channel"
            label="Select Channel"
            placeholder="Channels"
            value={JSON.stringify(channelData)}
            onChange={(e) => {
              setChannelData(JSON.parse(e.target.value));
              dispatch(setChannelId(JSON.parse(e.target.value).id));
            }}
          >
            {channels.map((channel) => (
              <MenuItem key={channel.id} value={JSON.stringify(channel)}>
                {channel.pageName || channel.channelType}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <Autocomplete
          id="channel"
          sx={{ width: 200 }}
          options={channels}
          autoHighlight
          getOptionLabel={(option) => option?.pageName || option?.channelType}
          value={channelData}
          onChange={(event, newValue) => {
            setChannelData(newValue);
            if (newValue) { // Check if newValue is not null
              dispatch(setChannelId(newValue.id)); // Use the id from the newValue directly
              dispatch(setChannel(newValue)); // Pass the entire channel object if needed
            } else {
              // Handle the case where the Autocomplete is cleared
              dispatch(setChannelId(null));
              dispatch(setChannel(null)); // Pass null if the Autocomplete is cleared
            }
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <ListItemIcon>
                {option.channelType === 'FACEBOOK' ? <FacebookIcon fontSize="small" /> : <GoogleIcon fontSize="small" />}
              </ListItemIcon>
              <ListItemText primary={option.pageName || option.channelType} />
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Channel"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Collapse>
    );
  };

  const renderNoChannelsMessage = () => {
    if (businessId && !isLoading && !isError && (!channels || channels.length === 0)) {
      return (
        <Grow in={channels.length === 0}>
          <Typography fontSize="0.875rem">No channel found</Typography>
        </Grow>
      );
    }
    return null;
  };

  useEffect(() => {
    // Auto-select business and channels if available
    if (business) {
      setBusinessId(business.id);
      if (channels?.length > 0) {
        setChannelSelectVisible(true);
      }
    }
  }, [business, channels]);

  useEffect(() => {
    if (channel) {
      setChannelData(channel);
    }
  }, [channel]);

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" minHeight="5.125rem" px={4} borderBottom="1px solid rgba(255, 255, 255, 0.075)">
      <Box>
        <Stack direction="row" gap={3} justifyContent="center" alignItems="center">
          <FormControl sx={{ width: '12rem' }}>
            <InputLabel id="select-active">Select Business</InputLabel>
            <Select
              id="business"
              name="business"
              labelId="business"
              label="Select Business"
              placeholder="business"
              value={business ? JSON.stringify(business) : ''}
              onChange={handleChange}
            >
              {businesses?.map((business) => (
                <MenuItem key={business.id} value={JSON.stringify(business)}>
                  {business.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {renderChannelSelect()}
          <Box>
            {renderNoChannelsMessage()}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
