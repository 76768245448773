import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import cardFailureImage from 'assets/card-failure.png';
import { useNavigate } from 'react-router-dom';

export default function AddCardFailure() {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <img src={cardFailureImage} alt="card-failure" style={{ width: '20rem' }} />
      <Stack textAlign="center" spacing={4} mt={-6} width="45rem">
        <Typography variant="h2" component="h2">Sorry! it looks like payment method addition failed</Typography>
        <Typography variant="h4" component="h4" color="gray" px="1rem">The card addition process was unsuccessful as the card has been canceled by the user. You may consider using a different card for addition</Typography>
      </Stack>
      <Button onClick={() => navigate('/payment/all')} variant="contained" sx={{ mt: 8, width: '45rem', fontSize: '1rem' }}>Go Back</Button>
    </Box>
  );
}
