import { Alert } from '@mui/material';

function ErrorAlert({ title, type = 'error', ...params }) {
  return (
    <Alert severity={type} sx={{ ...params }}>
      {title}
    </Alert>
  );
}

export default ErrorAlert;
