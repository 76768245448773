import { Box, Paper, Typography } from '@mui/material';
import Sidebar from 'components/sidebar/Sidebar';
import { navItems } from 'components/sidebar/dashboardItems';
import BusinessChannelSelectorBar from 'components/ui/BusinessChannelSelectorBar';
import ContentLoader from 'components/ui/ContentLoader';
import { Suspense, lazy, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components/macro';

const AllMessages = lazy(() => import('components/message/AllMessage'));

const drawerWidth = 300;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

export default function ConversationLayout() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Paper>
      <Box display="flex" height="100vh">
        <Drawer>
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={navItems}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              items={navItems}
            />
          </Box>
        </Drawer>
        <Box display="flex" height="100%" width="100%">
          <Box flex="0 0 auto" border="1px solid rgba(255, 255, 255, 0.075)">
            <ErrorBoundary fallback={(
              <Box>
                <Typography>An error is occurred</Typography>
              </Box>
            )}
            >
              <Suspense fallback={<ContentLoader />}>
                <AllMessages />
              </Suspense>
            </ErrorBoundary>
          </Box>
          <Box flexGrow={1}>
            <BusinessChannelSelectorBar />
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
