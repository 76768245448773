import { Table, tableCellClasses } from '@mui/material';

export default function StyledTable({ children, ...props }) {
  return (
    <Table
      sx={{
        border: 'none',
        [`& .${tableCellClasses.root}`]: {
          borderBottom: 'none',
        },
        minWidth: '100%',
      }}
      {...props}
    >
      {children}
    </Table>
  );
}
