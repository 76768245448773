import { Navigate } from 'react-router-dom';

export default function PublicRoute({ children }) {
  // Safely parse the 'auth' item from localStorage
  let userInfo;
  try {
    const auth = JSON.parse(window.localStorage.getItem('auth'));
    userInfo = auth?.user;
  } catch (error) {
    // Handle error or set userInfo to undefined/null
    userInfo = null;
  }

  // Check if userInfo exists and has an id, if so, redirect, otherwise render children
  if (userInfo?.id) {
    return <Navigate to="/" replace />;
  }

  return children;
}
