// @ts-check
/* eslint-disable comma-dangle */
import {
  green, grey, indigo, red,
} from '@mui/material/colors';
import merge from 'deepmerge';
import { THEMES } from '../utils/constants';

const customColor = {
  20: '#F9FBFF',
  30: '#ECF2FF',
  50: '#98A2FF',
  100: '#7683FF',
  200: '#5464FF',
  300: '#4351D3',
  400: '#323DA6',
  500: '#222A7A',
  600: '#192064',
  700: '#11164D',
  800: '#080D37',
  900: '#000321',
};

const customColorNew = {
  20: '#ecfdff',
  50: '#d0f6fd',
  70: '#a7ecfa',
  90: '#6bddf5',
  100: '#1cc1e8',
  200: '#020011',
  300: '#b8f5ff',
  400: '#383643',
  500: '#26242d',
};

const customColorNewDark = {
  100: '#E6E6EB',
  200: '#BFBFCC',
  300: '#9999AD',
  400: '#73738E',
  500: '#4D4D6E',
  600: '#383853',
  700: '#232338',
  800: '#0F0F1C',
  900: '#020011',
};

const customTealColor = {
  50: '#ecfdff',
  100: '#d0f6fd',
  200: '#a7ecfa',
  300: '#6bddf5',
  400: '#1cc1e8',
  500: '#0ba6cf',
  600: '#0c85ae',
  700: '#116b8d',
  800: '#175873',
  900: '#184961',
  950: '#092f43',
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: 'light',
    primary: {
      main: customColorNew[200],
      contrastText: customColorNew[300],
    },
    secondary: {
      main: customColor[50],
      contrastText: '#FFF',
    },
    background: {
      default: customColor[20],
      paper: '#FFF',
    },
  },
  header: {
    color: grey[900],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: customColor[600],
    },
  },
  footer: {
    color: grey[500],
    background: '#FFF',
    border: '#E1E3E6',
  },
  sidebar: {
    color: grey[900],
    background: customColor[20],
    header: {
      color: grey[200],
      background: '#233044',
      brand: {
        color: customColor[500],
      },
    },
    footer: {
      color: grey[900],
      background: customColor[20],
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: customColor[500],
    },
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: 'dark',
    primary: {
      main: customTealColor[300],
      contrastText: customTealColor[950],
    },
    background: {
      default: customColorNew[200],
      paper: customColorNew[200],
    },
    text: {
      primary: customColorNew[20],
      secondary: customColorNew[50],
    },
    message: {
      text: customColorNew[400],
      background: customTealColor[300],
    },
  },
  header: {
    color: grey[300],
    background: '#1B2635',
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[500],
    background: customColorNew[200],
    border: '#E1E3E6',
  },
  sidebar: {
    color: customColorNew[20],
    background: customColorNew[200],
    header: {
      color: grey[200],
      background: '#233044',
      brand: {
        color: customColor[500],
      },
    },
    footer: {
      color: customColorNew[20],
      background: customColorNew[200],
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: customColor[500],
    },
  },
});

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    mode: 'light',
  },
  header: {
    color: grey[200],
    background: customColor[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: '#FFF',
    header: {
      color: '#FFF',
      background: customColor[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: grey[800],
      background: '#F7F7F7',
      online: {
        background: green[500],
      },
    },
  },
});

const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: 'light',
  },
  sidebar: {
    color: '#FFF',
    background: customColor[700],
    header: {
      color: '#FFF',
      background: customColor[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: customColor[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
});

const greenVariant = merge(defaultVariant, {
  name: THEMES.GREEN,
  palette: {
    primary: {
      main: green[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: green[500],
      contrastText: '#FFF',
    },
  },
  header: {
    indicator: {
      background: green[600],
    },
  },
  sidebar: {
    color: '#FFF',
    background: green[700],
    header: {
      color: '#FFF',
      background: green[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: green[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
});

const indigoVariant = merge(defaultVariant, {
  name: THEMES.INDIGO,
  palette: {
    primary: {
      main: indigo[600],
      contrastText: '#FFF',
    },
    secondary: {
      main: indigo[400],
      contrastText: '#FFF',
    },
  },
  header: {
    indicator: {
      background: indigo[600],
    },
  },
  sidebar: {
    color: '#FFF',
    background: indigo[700],
    header: {
      color: '#FFF',
      background: indigo[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: indigo[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
});

const variants = [
  defaultVariant,
  darkVariant,
  lightVariant,
  blueVariant,
  greenVariant,
  indigoVariant,
];

export default variants;
export {
  customColor,
  customColorNew,
  customColorNewDark,
  customTealColor
};
